import React from 'react'
import moment from 'moment'
import { Button, withStyles, WithStyles, Grid, Typography } from '@material-ui/core'

import { SERVICE_LAUNCHED_AT } from 'utils/Constants'
import CalendarButton from './CalendarButton'
import { monthStyles } from './styles'

type MonthsProps = WithStyles<typeof monthStyles> & {
  activeDate: moment.Moment
  onChange: (from: moment.Moment, to: moment.Moment) => void
}

interface MonthsStates {
  offset: number
}

class Months extends React.Component<MonthsProps, MonthsStates> {
  constructor(props: MonthsProps) {
    super(props)
    this.state = { offset: 0 }
  }

  render() {
    const { offset } = this.state
    const { classes, activeDate, onChange } = this.props

    const launchedDate = moment(SERVICE_LAUNCHED_AT).startOf('month')
    const baseDate = moment(activeDate).startOf('month')
    const baseYear = baseDate.year() + offset
    const months = [...Array(3)].map((_, i) => [...Array(4)].map((_, j) => i * 4 + j))

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item container direction="row" alignItems="center">
          <Button
            className={classes.slideButton}
            disabled={moment([baseYear]).isBefore(launchedDate)}
            onClick={() => this.setState({ offset: offset - 1 })}>
            ＜
          </Button>
          <Grid item xs>
            <Typography align="center">{baseYear}年</Typography>
          </Grid>
          <Button
            className={classes.slideButton}
            disabled={moment([baseYear]).isSameOrAfter(moment().startOf('year'))}
            onClick={() => this.setState({ offset: offset + 1 })}>
            ＞
          </Button>
        </Grid>
        <Grid container direction="column">
          {months.map((row, index) => {
            return (
              <Grid item container direction="row" key={index}>
                {row.map((month) => {
                  const date = moment([baseYear]).add(month, 'month')
                  return (
                    <CalendarButton
                      className={classes.button}
                      key={month}
                      selected={date.isSame(baseDate)}
                      disabled={date.isBefore(launchedDate) || date.isAfter(moment())}
                      title={`${month + 1}月`}
                      onClick={() => onChange(date, moment(date).endOf('month'))}
                    />
                  )
                })}
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(monthStyles)(Months)
