import { HeatRiskTrendData } from 'utils/apiClient'
import moment from 'moment'
import User from './user'

export default class HeatRiskTrend {
  id: number
  date: moment.Moment
  minValue: number
  maxValue: number
  averageValue: number
  count: number
  user: User
  createdAt: moment.Moment

  constructor(
    id: number,
    date: moment.Moment,
    minValue: number,
    maxValue: number,
    averageValue: number,
    count: number,
    user: User,
    createdAt: moment.Moment,
  ) {
    this.id = id
    this.date = date
    this.minValue = minValue
    this.maxValue = maxValue
    this.averageValue = averageValue
    this.count = count
    this.user = user
    this.createdAt = createdAt
  }

  static load(data: HeatRiskTrendData): HeatRiskTrend {
    return new HeatRiskTrend(
      data.id,
      moment.unix(data.timestamp),
      data.minValue,
      data.maxValue,
      data.averageValue,
      data.count,
      User.load(data.user),
      moment(data.createdAt),
    )
  }

  static loadAll(data: HeatRiskTrendData[]): HeatRiskTrend[] {
    return data.map((item) => this.load(item))
  }
}
