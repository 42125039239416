import moment from 'moment'

const _TrendType = {
  Day: 'day',
  Week: 'isoWeek',
  Month: 'month',
  Year: 'year',
} as const
export type TrendType = typeof _TrendType[keyof typeof _TrendType]

export const TrendType = {
  ..._TrendType,

  momentUnit: (type: TrendType) => (type === _TrendType.Week ? 'week' : type),
  graphUnit: (type: TrendType) => {
    switch (type) {
      case _TrendType.Day:
        return 'hour'
      case _TrendType.Week:
        return 'day'
      case _TrendType.Month:
        return 'day'
      case _TrendType.Year:
        return 'day'
    }
  },
  graphLabel: (type: TrendType, date: moment.Moment) => {
    switch (type) {
      case _TrendType.Day:
        if (date.minute() !== 0) return null
        return date.format('H')
      case _TrendType.Week:
        return date.format('M/D')
      case _TrendType.Month: {
        const day = date.date()
        if (day !== 1 && day % 7 > 0) return ''
        return day === 1 ? date.format('M/D') : date.format('D')
      }
      case _TrendType.Year: {
        const day = date.date()
        if (day !== 1) return null
        return date.format('M')
      }
    }
  },
  trendLabel: (type: TrendType, from: moment.Moment, to: moment.Moment, isAverage = false) => {
    switch (type) {
      case _TrendType.Day:
        return isAverage ? `${from.format('M/D')}` : `${from.format('HH:mm')}~${to.format('HH:mm')}`
      case _TrendType.Week:
        return isAverage ? `${from.format('M/D')}~${to.format('M/D')}` : `${from.format('M/D')}`
      case _TrendType.Month:
        return isAverage ? `${from.format('M')}月` : `${from.format('M/D')}`
      case _TrendType.Year: {
        return isAverage ? `${from.format('YYYY')}年` : `${from.format('M/D')}`
      }
    }
  },
}
