import React from 'react'
import clsx from 'clsx'
import { withStyles, WithStyles, Grid } from '@material-ui/core'
import { createStyles } from '@material-ui/core'
import colors from 'utils/Color'
import { ConditionIcon } from 'utils/Icon'

const styles = () =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    svgContainer: {
      width: '100%',
      height: '100%',
    },
    iconContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    icon: {
      width: '100%',
      height: '100%',
    },
  })

type Props = WithStyles<typeof styles> & {
  className?: string
  values: {
    label: string | null
    value: number | null
  }[]
}

interface States {
  dimension: {
    width: number
    height: number
  }
}

class Graph extends React.Component<Props, States> {
  component: HTMLDivElement | null = null

  constructor(props: Props) {
    super(props)

    this.state = {
      dimension: {
        width: 0,
        height: 0,
      },
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.resizeHandler())
    this.resizeHandler()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resizeHandler())
  }

  resizeHandler() {
    if (!this.component) return

    this.setState({
      dimension: {
        width: this.component.offsetWidth,
        height: this.component.offsetHeight,
      },
    })
  }

  getColor(value: number) {
    switch (value) {
      case 0:
        return colors.ConditionGreen
      case 1:
        return colors.ConditionYellow
      case 2:
        return colors.ConditionRed
    }
  }

  getIcon(value: number) {
    switch (value) {
      case 0:
        return ConditionIcon.HeatGood
      case 1:
        return ConditionIcon.HeatWarning
      case 2:
        return ConditionIcon.HeatBad
    }
  }

  render() {
    const { dimension } = this.state
    const { classes, className, values } = this.props
    const yAxixSpace = 50
    const xAxixSpace = 20
    const topMargin = 15
    const graphDimension = {
      width: dimension.width - yAxixSpace,
      height: dimension.height - topMargin - xAxixSpace,
    }
    const dx = graphDimension.width / values.length
    const dy = graphDimension.height / 3

    const graphLineStyle = (width = 2): React.CSSProperties => ({
      stroke: colors.LightGray,
      strokeWidth: width,
      fillOpacity: 0,
    })

    return (
      <div ref={(ref) => (this.component = ref)} className={clsx(classes.container, className)}>
        <svg
          className={classes.svgContainer}
          viewBox={`0 0 ${dimension.width} ${dimension.height}`}>
          {values.map(({ label }, index) => (
            <React.Fragment key={index}>
              {label !== null && (
                <line
                  style={graphLineStyle(0.5)}
                  x1={`${yAxixSpace + dx / 2 + dx * index}`}
                  x2={`${yAxixSpace + dx / 2 + dx * index}`}
                  y1={`${topMargin}`}
                  y2={`${topMargin + graphDimension.height}`}
                  strokeDasharray="1 3"
                />
              )}
              <text x={yAxixSpace + dx / 2 + dx * index} y={dimension.height} textAnchor="middle">
                {label}
              </text>
            </React.Fragment>
          ))}

          <path
            style={graphLineStyle()}
            d={`
              M ${yAxixSpace},${topMargin}
              V ${topMargin + graphDimension.height}
              H ${yAxixSpace + graphDimension.width}
            `}
          />

          {['警告', '注意', '正常'].map((label, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <path
                  style={graphLineStyle(0.5)}
                  d={`
                    M ${yAxixSpace},${topMargin + dy * index}
                    H ${yAxixSpace + graphDimension.width}
                  `}
                />
              )}
              <text x={yAxixSpace - 5} y={topMargin + dy * index + dy / 2} textAnchor="end">
                {label}
              </text>
            </React.Fragment>
          ))}
        </svg>
        <div className={classes.iconContainer}>
          {values.map(({ value }, index) => {
            if (value === null) return <React.Fragment key={index}></React.Fragment>

            const iconSize = Math.min(dy, dx) * 0.9
            const style: React.CSSProperties = {
              position: 'absolute',
              top: topMargin + dy * (2 - value) + dy / 2 - iconSize / 2,
              left: yAxixSpace + dx / 2 + dx * index - iconSize / 2,
              width: iconSize,
              height: iconSize,
              padding: 5,
              backgroundColor: this.getColor(value),
              borderRadius: 5,
            }
            return (
              <Grid key={index} style={style}>
                <img src={this.getIcon(value)} className={classes.icon} alt="icon" />
              </Grid>
            )
          })}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Graph)
