import React from 'react'
import clsx from 'clsx'
import { Link as RouterLink, withRouter, RouteComponentProps } from 'react-router-dom'
import {
  withStyles,
  WithStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@material-ui/core'

import { navigationStyles } from './styles'

type NavigatorItemProps = RouteComponentProps &
  WithStyles<typeof navigationStyles> & {
    title: string
    icon: (active: boolean) => React.ReactElement
    to: string
    pattern: RegExp
    badge: number | null
    onClick: () => void
  }

function NavigatorItem(props: NavigatorItemProps) {
  const { location, classes, title, icon, to, pattern, badge, onClick } = props
  const isActive = !!location.pathname.match(pattern)

  const hasScheme = to.includes('http')

  return (
    <ListItem
      button
      component={hasScheme ? 'a' : RouterLink}
      to={to}
      href={to}
      target={hasScheme ? '_blank' : '_self'}
      className={clsx(classes.item, isActive && classes.activeItem)}
      onClick={onClick}>
      <ListItemIcon className={classes.itemIcon}>{icon(isActive)}</ListItemIcon>
      <ListItemText
        classes={{
          primary: isActive ? classes.activeItemPrimary : classes.itemPrimary,
        }}>
        {title}
      </ListItemText>
      {!!badge && <Badge badgeContent={badge} color="primary"></Badge>}
    </ListItem>
  )
}

export default withRouter(withStyles(navigationStyles)(NavigatorItem))
