import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import {
  withStyles,
  WithStyles,
  Typography,
  Grid,
  Paper,
  Theme,
  createStyles,
} from '@material-ui/core'
import { GraphsState } from 'redux/modules/graphs'
import { TrendType } from 'utils/trendType'
import Graph from './Graph'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
    },
    title: {
      fontSize: 17,
      fontWeight: 'bold',
    },
    graph: {
      width: 'calc(100% - 10px)',
      height: 300,
      margin: 5,
    },
  })

type HeatRiskGraphProps = WithStyles<typeof styles> & {
  className?: string
  graphs: GraphsState
}

const HeatRiskGraph = (props: HeatRiskGraphProps) => {
  const { classes, className, graphs } = props
  if (!graphs.data) return <></>
  const { type, from, to } = graphs.trend
  const { heatRisks } = graphs.data

  const values: {
    label: string | null
    value: number | null
  }[] = []

  const date = moment(from)
  while (date < to) {
    const heatRisk = heatRisks.find((heatRisk) => heatRisk.date.unix() === date.unix())
    let value: number | null = null
    if (heatRisk) value = Math.round(Math.round(heatRisk.maxValue))
    values.push({
      label: TrendType.graphLabel(type, date),
      value,
    })
    date.add(1, TrendType.graphUnit(type))
  }

  return (
    <Paper className={clsx(classes.container, className)}>
      <Typography className={classes.title}>暑熱リスク</Typography>
      <Grid container direction="row">
        <Graph className={classes.graph} values={values} />
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(HeatRiskGraph)
