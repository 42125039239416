import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core'
import { createStyles } from '@material-ui/core'
import colors from 'utils/Color'

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
  })

type Props = WithStyles<typeof styles> & {
  className?: string
  values: {
    label: string | null
    value: number | null
  }[]
}

interface States {
  dimension: {
    width: number
    height: number
  }
}

class Graph extends React.Component<Props, States> {
  component: HTMLDivElement | null = null

  constructor(props: Props) {
    super(props)

    this.state = {
      dimension: {
        width: 0,
        height: 0,
      },
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.resizeHandler())
    this.resizeHandler()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resizeHandler())
  }

  resizeHandler() {
    if (!this.component) return

    this.setState({
      dimension: {
        width: this.component.offsetWidth,
        height: this.component.offsetHeight,
      },
    })
  }

  render() {
    const { dimension } = this.state
    const { classes, className, values } = this.props
    const yAxixSpace = 30
    const xAxixSpace = 20
    const topMargin = 15
    const minMaxLabelSpace = 20
    const graphDimension = {
      width: dimension.width - yAxixSpace - minMaxLabelSpace,
      height: dimension.height - topMargin - xAxixSpace,
    }
    const dx = graphDimension.width / values.length
    const dy = graphDimension.height / 5

    let minValue = 1_000
    let maxValue = 0
    let sum = 0
    let count = 0
    values.forEach(({ value }) => {
      if (value === null) return
      if (minValue > value) minValue = Math.round(value)
      if (maxValue < value) maxValue = Math.round(value)
      sum += value
      count += 1
    })
    const averageValue = Math.floor(sum / Math.max(1, count))

    const graphLineStyle = (width = 2): React.CSSProperties => ({
      stroke: colors.LightGray,
      strokeWidth: width,
      fillOpacity: 0,
    })

    return (
      <div ref={(ref) => (this.component = ref)} className={className}>
        <svg className={classes.container} viewBox={`0 0 ${dimension.width} ${dimension.height}`}>
          <defs>
            <linearGradient id="stress-gradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="10%" stopColor={colors.ConditionRed} />
              <stop offset="20%" stopColor={colors.ConditionYellow} />
              <stop offset="100%" stopColor={colors.ConditionGreen} />
            </linearGradient>

            <mask id="stress-mask">
              <rect width={dimension.width} height={dimension.height} fill="black" />
              {values.map(
                ({ value }, index) =>
                  value !== null && (
                    <React.Fragment key={index}>
                      <line
                        x1={`${yAxixSpace + dx / 2 + dx * index}`}
                        x2={`${yAxixSpace + dx / 2 + dx * index}`}
                        y1={`${topMargin + graphDimension.height}`}
                        y2={`${topMargin + graphDimension.height * (1 - value / 100)}`}
                        stroke="white"
                        strokeWidth={dx * 0.8}
                      />
                    </React.Fragment>
                  ),
              )}
              {count > 0 &&
                [minValue, maxValue, averageValue].map((value, index) => (
                  <React.Fragment key={index}>
                    <line
                      x1={`${yAxixSpace}`}
                      x2={`${yAxixSpace + graphDimension.width}`}
                      y1={`${topMargin + graphDimension.height * (1 - value / 100)}`}
                      y2={`${topMargin + graphDimension.height * (1 - value / 100)}`}
                      stroke="white"
                      strokeWidth={1}
                    />
                    <text
                      x={dimension.width}
                      y={topMargin + graphDimension.height * (1 - value / 100) - 5}
                      textAnchor="end"
                      stroke="white">
                      {value}
                    </text>
                  </React.Fragment>
                ))}
            </mask>
          </defs>

          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <path
                  style={graphLineStyle(0.5)}
                  d={`
                    M ${yAxixSpace},${topMargin + dy * index}
                    H ${yAxixSpace + graphDimension.width}
                  `}
                />
              )}
              <text x={yAxixSpace - 5} y={topMargin + dy * index + 4} textAnchor="end">
                {100 - index * 20}
              </text>
            </React.Fragment>
          ))}

          {values.map(({ label }, index) => (
            <React.Fragment key={index}>
              {label !== null && (
                <line
                  style={graphLineStyle(0.5)}
                  x1={`${yAxixSpace + dx / 2 + dx * index}`}
                  x2={`${yAxixSpace + dx / 2 + dx * index}`}
                  y1={`${topMargin}`}
                  y2={`${topMargin + graphDimension.height}`}
                  strokeDasharray="1 3"
                />
              )}
              <text x={yAxixSpace + dx / 2 + dx * index} y={dimension.height} textAnchor="middle">
                {label}
              </text>
            </React.Fragment>
          ))}

          <rect
            width={dimension.width}
            height={dimension.height}
            fill="url('#stress-gradient')"
            mask="url('#stress-mask')"
          />

          <path
            style={graphLineStyle()}
            d={`
              M ${yAxixSpace},${topMargin}
              V ${topMargin + graphDimension.height}
              H ${yAxixSpace + graphDimension.width}
            `}
          />
        </svg>
      </div>
    )
  }
}

export default withStyles(styles)(Graph)
