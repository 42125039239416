import { ObservationData } from 'utils/apiClient'
import moment from 'moment'
import { formatDateTime } from 'utils/moment'

export const ObservationStatus = {
  None: 0,
  Pending: 1,
  Approved: 2,
  Rejected: 3,
} as const
export type ObservationStatus = typeof ObservationStatus[keyof typeof ObservationStatus]

export const statusTexts = {
  [ObservationStatus.None]: '未申請',
  [ObservationStatus.Pending]: '承認待ち',
  [ObservationStatus.Approved]: '承認済み',
  [ObservationStatus.Rejected]: '却下',
}

export default class Observation {
  id: number
  code: string
  name: string
  status: ObservationStatus
  createdAt: moment.Moment

  get statusText(): string {
    return statusTexts[this.status]
  }

  get createdDate(): string {
    return formatDateTime(this.createdAt)
  }

  get isNoneStatus() {
    return this.status === ObservationStatus.None
  }

  constructor(
    id: number,
    code: string,
    name: string,
    status: ObservationStatus,
    createdAt: moment.Moment,
  ) {
    this.id = id
    this.code = code
    this.name = name
    this.status = status
    this.createdAt = createdAt
  }

  static load(data: ObservationData): Observation {
    return new Observation(
      data.id,
      data.userCode,
      data.name,
      data.observationStatus,
      moment(data.createdAt),
    )
  }

  static loadAll(data: ObservationData[]): Observation[] {
    return data.map((item) => this.load(item))
  }
}
