import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { Grid, WithStyles, withStyles, Typography } from '@material-ui/core'

import Constants from 'utils/Constants'
import { Environment } from 'utils/environment'

import { Form, GreenButton } from 'components'
import { LoginActions, LoginStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type LoginProps = OwnProps & LoginStoreStates & LoginActions

interface LoginState {
  groupCode: string
  userCode: string
  password: string
}

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props)

    this.state =
      Constants.ENV === Environment.Development
        ? {
            groupCode: 'thomas-lab',
            userCode: 'kirikihira',
            password: '1234Password',
          }
        : {
            groupCode: '',
            userCode: '',
            password: '',
          }
  }

  onSubmit() {
    const { groupCode, userCode, password } = this.state
    this.props.logIn({ groupCode, userCode, password })
  }

  render() {
    const { groupCode, userCode, password } = this.state
    const { classes, auth } = this.props
    const { loggingIn, logInError, user } = auth
    const disable = [groupCode, userCode, password].includes('') || loggingIn

    if (user) {
      return <Redirect to="/" />
    }

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.container}>
        <Grid container direction="column" alignItems="center" className={classes.contentContainer}>
          <Typography className={classes.title}>hamon</Typography>
          {logInError && <Typography className={classes.error}>{logInError}</Typography>}
          <Form
            value={groupCode}
            label="企業コード"
            onChange={(groupCode) => this.setState({ groupCode })}
          />
          <Form
            value={userCode}
            label="ユーザーID"
            onChange={(userCode) => this.setState({ userCode })}
          />
          <Form
            value={password}
            label="パスワード"
            type="password"
            onChange={(password) => this.setState({ password })}
          />
          <GreenButton
            variant="contained"
            disabled={disable}
            title="ログイン"
            className={classes.button}
            onClick={() => this.onSubmit()}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(Login)
