import React from 'react'
import clsx from 'clsx'
import { Typography, withStyles, WithStyles, Theme, Grid } from '@material-ui/core'
import { createStyles } from '@material-ui/core'
import colors from 'utils/Color'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 15,
    },
    contentContainer: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: 5,
      backgroundColor: colors.Background,
    },
    numberLabel: {
      margin: theme.spacing(0.5),
      fontSize: 15,
      fontWeight: 'bold',
    },
    percentageLabel: {
      marginLeft: theme.spacing(3),
      fontSize: 15,
    },
  })

type Props = WithStyles<typeof styles> & {
  label: string
  heartRate: number
  date: string
  className?: string
}

const TrendLabel = (props: Props) => {
  const { classes, className, label, heartRate, date } = props

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      className={clsx(classes.container, className)}>
      <Grid item>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>
      <Grid
        item
        xs
        container
        direction="column"
        alignItems="center"
        spacing={1}
        className={classes.contentContainer}>
        <Grid item>
          <Typography>
            <Typography component="span" className={classes.numberLabel}>
              {heartRate}
            </Typography>
            <Typography component="span">拍/分</Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center">{date}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(TrendLabel)
