import { channel } from 'redux-saga'
import { put, takeEvery, take, ChannelTakeEffect } from 'redux-saga/effects'

import apiClient, { UserGroupData } from 'utils/apiClient'
import * as UserGroups from 'redux/modules/userGroups'
import UserGroup from 'redux/models/userGroup'

type AddUserGroupAction = ReturnType<typeof UserGroups.actions.addUserGroup>
type UpdateUserGroupAction = ReturnType<typeof UserGroups.actions.updateUserGroup>
type DeleteUserGroupAction = ReturnType<typeof UserGroups.actions.deleteUserGroup>
type UpdateUserGroupConfigAction = ReturnType<typeof UserGroups.actions.updateUserGroupConfig>

const redirectChannel = channel()

const fetchList = function* () {
  try {
    const data: UserGroupData[] = yield apiClient.getUserGroups()
    const userGroups = UserGroup.loadAll(data)
    yield put(UserGroups.actions.succeededToFetchList(userGroups))
  } catch (err) {
    console.error(err)
    yield put(UserGroups.actions.failedToFetchList('取得に失敗しました'))
  }
}

const addUserGroup = function* (action: AddUserGroupAction) {
  try {
    const body = action.payload
    if (body.availableTo) body.availableTo = body.availableTo.add(1, 'day').subtract(1, 'second') // 日の最後にする

    const data: UserGroupData = yield apiClient.addUserGroup(body)
    const userGroup = UserGroup.load(data)
    yield put(UserGroups.actions.succeededToAddUserGroup(userGroup))
  } catch (err) {
    console.error(err)
    yield put(UserGroups.actions.failedToAddUserGroup('作成できませんでした'))
  }
}

const updateUserGroup = function* (action: UpdateUserGroupAction) {
  try {
    const { group, ...payload } = action.payload
    const body = {
      id: group.id,
      ...payload,
    }
    if (body.availableTo) body.availableTo = body.availableTo.add(1, 'day').subtract(1, 'second') // 日の最後にする

    const data: UserGroupData = yield apiClient.updateUserGroup(body)
    const userGroup = UserGroup.load(data)
    yield put(UserGroups.actions.succeededToUpdateUserGroup(userGroup))
  } catch (err) {
    console.error(err)
    yield put(UserGroups.actions.failedToUpdateUserGroup('更新できませんでした'))
  }
}

const deleteUserGroup = function* (action: DeleteUserGroupAction) {
  try {
    const { group, physics } = action.payload
    yield apiClient.deleteUserGroup(group.id, physics)
    yield put(UserGroups.actions.succeededToDeleteUserGroup(group))
  } catch (err) {
    console.error(err)
    yield put(UserGroups.actions.failedToDeleteUserGroup('削除できませんでした'))
  }
}

const updateUserGroupConfig = function* (action: UpdateUserGroupConfigAction) {
  try {
    const { group, configs } = action.payload

    yield apiClient.updateUserGroupConfig(group.id, configs)
    group.configs = configs
    yield put(UserGroups.actions.succeededToUpdateUserGroupConfig(group))
  } catch (err) {
    console.error(err)
    yield put(UserGroups.actions.failedToUpdateUserGroupConfig('更新できませんでした'))
  }
}

export default function* dataSaga() {
  yield takeEvery(UserGroups.FETCH_LIST, fetchList)
  yield takeEvery(UserGroups.ADD_USER_GROUP, addUserGroup)
  yield takeEvery(UserGroups.UPDATE_USER_GROUP, updateUserGroup)
  yield takeEvery(UserGroups.DELETE_USER_GROUP, deleteUserGroup)
  yield takeEvery(UserGroups.UPDATE_USER_GROUP_CONFIG, updateUserGroupConfig)

  while (true) {
    const action: ChannelTakeEffect<unknown> = yield take(redirectChannel)
    yield put(action)
  }
}
