import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import User from 'redux/models/user'

// Actions
export const LOAD = 'hamon/auth/LOAD'
export const SYNC_AUTH = 'hamon/auth/SYNC_AUTH'
export const LOGIN = 'hamon/auth/LOGIN'
export const LOGIN_SUCCESS = 'hamon/auth/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'hamon/auth/LOGIN_FAILURE'
export const LOGOUT = 'hamon/auth/LOGOUT'
export const LOGOUT_SUCCESS = 'hamon/auth/LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'hamon/auth/LOGOUT_FAILURE'
export const CHANGE_PASSWORD = 'hamon/auth/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'hamon/auth/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'hamon/auth/CHANGE_PASSWORD_FAILURE'
export const RESET_PASSWORD = 'hamon/auth/RESET_PASSWORD'
export const RESET_PASSWORD_COMPLETION = 'hamon/auth/RESET_PASSWORD_COMPLETION'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface LogInPayload {
  groupCode: string
  userCode: string
  password: string
}

export interface ChangePasswordPayload {
  currentPassword: string
  newPassword: string
}

export const actions = {
  load: actionCreator(LOAD),
  syncAuth: actionCreator<User | null>(SYNC_AUTH),
  logIn: actionCreator<LogInPayload>(LOGIN),
  succeededToLogIn: actionCreator<User>(LOGIN_SUCCESS),
  failedToLogIn: actionCreator<string>(LOGIN_FAILURE),
  logOut: actionCreator<void>(LOGOUT),
  succeededToLogOut: actionCreator<void>(LOGOUT_SUCCESS),
  failedToLogOut: actionCreator<string>(LOGOUT_FAILURE),
  changePassword: actionCreator<ChangePasswordPayload>(CHANGE_PASSWORD),
  succeededToChangePassword: actionCreator<void>(CHANGE_PASSWORD_SUCCESS),
  failedToChangePassword: actionCreator<string>(CHANGE_PASSWORD_FAILURE),
  resetPassword: actionCreator<string>(RESET_PASSWORD),
  completedToResetPassword: actionCreator(RESET_PASSWORD_COMPLETION),
}

// Reducer
export interface AuthState {
  user: User | null
  loading: boolean
  loaded: boolean
  loggingIn: boolean
  logInError: string | null
  loggingOut: boolean
  logOutError: string | null
  changingPassword: boolean
  changePasswordError: string | null
  resettingPassword: boolean
}

const initialState: () => AuthState = () => ({
  user: null,
  loading: false,
  loaded: false,
  loggingIn: false,
  logInError: null,
  loggingOut: false,
  logOutError: null,
  changingPassword: false,
  changePasswordError: null,
  resettingPassword: false,
})

export default reducerWithInitialState(initialState())
  .case(actions.load, (state) => ({
    ...state,
    loading: true,
  }))
  .case(actions.syncAuth, (state, user) => ({
    ...state,
    loading: false,
    loaded: true,
    user,
  }))
  .case(actions.logIn, (state) => ({
    ...state,
    loggingIn: true,
  }))
  .case(actions.succeededToLogIn, (state, user) => ({
    ...state,
    loggingIn: false,
    user,
  }))
  .case(actions.failedToLogIn, (state, error) => ({
    ...state,
    loggingIn: false,
    logInError: error,
  }))
  .case(actions.logOut, (state) => ({
    ...state,
    loggingOut: true,
  }))
  .case(actions.succeededToLogOut, (state) => ({
    ...state,
    loggingOut: false,
    user: null,
  }))
  .case(actions.failedToLogOut, (state, error) => ({
    ...state,
    loggingOut: false,
    logOutError: error,
  }))
  .case(actions.changePassword, (state) => ({
    ...state,
    changingPassword: true,
  }))
  .case(actions.succeededToChangePassword, (state) => ({
    ...state,
    changingPassword: false,
    changePasswordError: null,
  }))
  .case(actions.failedToChangePassword, (state, error) => ({
    ...state,
    changingPassword: false,
    changePasswordError: error,
  }))
  .case(actions.resetPassword, (state) => ({
    ...state,
    resettingPassword: true,
  }))
  .case(actions.completedToResetPassword, (state) => ({
    ...state,
    resettingPassword: false,
  }))
