import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core'
import { createStyles } from '@material-ui/core'
import colors from 'utils/Color'

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
  })

type Props = WithStyles<typeof styles> & {
  className?: string
  values: {
    label: string | null
    min: number | null
    max: number | null
    average: number | null
  }[]
  isShowValueLine: boolean
}

interface States {
  dimension: {
    width: number
    height: number
  }
}

class Graph extends React.Component<Props, States> {
  component: HTMLDivElement | null = null

  constructor(props: Props) {
    super(props)

    this.state = {
      dimension: {
        width: 0,
        height: 0,
      },
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.resizeHandler())
    this.resizeHandler()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resizeHandler())
  }

  resizeHandler() {
    if (!this.component) return

    this.setState({
      dimension: {
        width: this.component.offsetWidth,
        height: this.component.offsetHeight,
      },
    })
  }

  render() {
    const { dimension } = this.state
    const { classes, className, values, isShowValueLine } = this.props
    const yAxixSpace = 30
    const xAxixSpace = 20
    const topMargin = 15
    const graphMinValue = 20
    const graphMaxValue = 200
    const graphDimension = {
      width: dimension.width - yAxixSpace,
      height: dimension.height - topMargin - xAxixSpace,
    }
    const dx = graphDimension.width / values.length
    const dy = graphDimension.height / 9

    const graphLineStyle = (width = 2): React.CSSProperties => ({
      stroke: colors.LightGray,
      strokeWidth: width,
      fillOpacity: 0,
    })

    return (
      <div ref={(ref) => (this.component = ref)} className={className}>
        <svg className={classes.container} viewBox={`0 0 ${dimension.width} ${dimension.height}`}>
          <defs>
            <linearGradient id="heatrate-gradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="20%" stopColor={colors.ConditionRed} />
              <stop offset="40%" stopColor={colors.ConditionYellow} />
              <stop offset="80%" stopColor={colors.ConditionGreen} />
              <stop offset="100%" stopColor={colors.ConditionYellow} />
            </linearGradient>

            <mask id="heatrate-mask">
              <rect width={dimension.width} height={dimension.height} fill="black" />
              {isShowValueLine && (
                <path
                  d={values.reduce((d, { average }, index) => {
                    if (average === null) return d
                    return d === '' || values[index - 1].average === null
                      ? `${d} M ${yAxixSpace + dx / 2 + dx * index},${
                          topMargin +
                          graphDimension.height *
                            (1 - (average - graphMinValue) / (graphMaxValue - graphMinValue))
                        }`
                      : `${d} L ${yAxixSpace + dx / 2 + dx * index},${
                          topMargin +
                          graphDimension.height *
                            (1 - (average - graphMinValue) / (graphMaxValue - graphMinValue))
                        }`
                  }, '')}
                  stroke="white"
                  strokeWidth={1}
                />
              )}
              {values.map(({ average }, index) => (
                <React.Fragment key={index}>
                  {average !== null && (
                    <circle
                      cx={`${yAxixSpace + dx / 2 + dx * index}`}
                      cy={`${
                        topMargin +
                        graphDimension.height *
                          (1 - (average - graphMinValue) / (graphMaxValue - graphMinValue))
                      }`}
                      r="3"
                      fill="white"
                    />
                  )}
                </React.Fragment>
              ))}
            </mask>
          </defs>

          {[...Array(10)].map((_, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <path
                  style={graphLineStyle(0.5)}
                  d={`
                    M ${yAxixSpace},${topMargin + dy * index}
                    H ${yAxixSpace + graphDimension.width}
                  `}
                />
              )}
              <text x={yAxixSpace - 5} y={topMargin + dy * index + 4} textAnchor="end">
                {graphMaxValue - index * 20}
              </text>
            </React.Fragment>
          ))}

          {values.map(({ label }, index) => (
            <React.Fragment key={index}>
              {label !== null && (
                <line
                  style={graphLineStyle(0.5)}
                  x1={`${yAxixSpace + dx / 2 + dx * index}`}
                  x2={`${yAxixSpace + dx / 2 + dx * index}`}
                  y1={`${topMargin}`}
                  y2={`${topMargin + graphDimension.height}`}
                  strokeDasharray="1 3"
                />
              )}
              <text x={yAxixSpace + dx / 2 + dx * index} y={dimension.height} textAnchor="middle">
                {label}
              </text>
            </React.Fragment>
          ))}

          <rect
            width={dimension.width}
            height={dimension.height}
            fill="url('#heatrate-gradient')"
            mask="url('#heatrate-mask')"
          />

          <path
            style={graphLineStyle()}
            d={`
              M ${yAxixSpace},${topMargin}
              V ${topMargin + graphDimension.height}
              H ${yAxixSpace + graphDimension.width}
            `}
          />
        </svg>
      </div>
    )
  }
}

export default withStyles(styles)(Graph)
