export default {
  notEmpty: (...values: unknown[]) => {
    const invalids = values.filter((value) => value === null || value === undefined || value === '')
    if (invalids.length !== 0) throw new Error('未入力の項目があります')
  },
  selection: (id: number, list: number[], label = '') => {
    let message = '選択してください'
    if (label) message = `${label}を` + message
    if (!list.includes(id)) throw new Error(message)
  },
  groupCode: (code: string) => {
    if (!code.match(/^[0-9a-zA-Z-]+$/))
      throw new Error('企業コードは英数字とハイフンのみ許可されています')
  },
  userCode: (code: string) => {
    if (!code.match(/^[0-9a-zA-Z-_]+$/))
      throw new Error('ユーザーコードは英数字とハイフン，アンダーバーのみ許可されています')
  },
  password: (password: string) => {
    if (!password.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/))
      throw new Error(
        'パスワードは8文字以上100文字以下で，半角英大文字小文字数字をそれぞれ1つ以上含める必要があります',
      )
  },
  duplication: (values: string[], label: string) => {
    if (new Set(values).size !== values.length)
      throw new Error(`${label}が重複しているものがあります`)
  },
}
