import { UserGroupData } from 'utils/apiClient'
import moment from 'moment'
import { formatDate, formatDateTime } from 'utils/moment'
import { Platform } from 'utils/platform'

const UserGroupConfigKey = {
  // 指標のON/OFF
  isCoreBodyTemperatureAnalysisEnabled: 'isCoreBodyTemperatureAnalysisEnabled',
  isDrowsinessAnalysisEnabled: 'isDrowsinessAnalysisEnabled',
  isHyperthermiaAnalysisEnabled: 'isHyperthermiaAnalysisEnabled',
  isStressAnalysisEnabled: 'isStressAnalysisEnabled',
  isTumbleAnalysisEnabled: 'isTumbleAnalysisEnabled',

  // プッシュ通知のON/OFF
  isCoreBodyTemperaturePushNotificationEnabled: 'isCoreBodyTemperaturePushNotificationEnabled',
  isDrowsinessPushNotificationEnabled: 'isDrowsinessPushNotificationEnabled',
  isHyperthermiaPushNotificationEnabled: 'isHyperthermiaPushNotificationEnabled',
  isStressPushNotificationEnabled: 'isStressPushNotificationEnabled',
  isTumblePushNotificationEnabled: 'isTumblePushNotificationEnabled',

  // アラート発出件数のON/OFF
  isCoreBodyTemperatureAlertCountEnabled: 'isCoreBodyTemperatureAlertCountEnabled',
  isDrowsinessAlertCountEnabled: 'isDrowsinessAlertCountEnabled',
  isHyperthermiaAlertCountEnabled: 'isHyperthermiaAlertCountEnabled',
  isStressAlertCountEnabled: 'isStressAlertCountEnabled',
} as const
type UserGroupConfigKey = typeof UserGroupConfigKey[keyof typeof UserGroupConfigKey]
export { UserGroupConfigKey }

export const getLabelForKey = (key: UserGroupConfigKey) => {
  switch (key) {
    // 指標のON/OFF
    case UserGroupConfigKey.isCoreBodyTemperatureAnalysisEnabled:
      return '指標計算 - 深部体温'
    case UserGroupConfigKey.isDrowsinessAnalysisEnabled:
      return '指標計算 - 眠気'
    case UserGroupConfigKey.isHyperthermiaAnalysisEnabled:
      return '指標計算 - 体調指数'
    case UserGroupConfigKey.isStressAnalysisEnabled:
      return '指標計算 - ストレス'
    case UserGroupConfigKey.isTumbleAnalysisEnabled:
      return '指標計算 - 転倒'

    // プッシュ通知のON/OFF
    case UserGroupConfigKey.isCoreBodyTemperaturePushNotificationEnabled:
      return 'プッシュ通知 - 深部体温'
    case UserGroupConfigKey.isDrowsinessPushNotificationEnabled:
      return 'プッシュ通知 - 眠気'
    case UserGroupConfigKey.isHyperthermiaPushNotificationEnabled:
      return 'プッシュ通知 - 体調指数'
    case UserGroupConfigKey.isStressPushNotificationEnabled:
      return 'プッシュ通知 - ストレス'
    case UserGroupConfigKey.isTumblePushNotificationEnabled:
      return 'プッシュ通知 - 転倒'

    // アラート発出件数のON/OFF
    case UserGroupConfigKey.isCoreBodyTemperatureAlertCountEnabled:
      return 'アラート発出件数 - 深部体温'
    case UserGroupConfigKey.isDrowsinessAlertCountEnabled:
      return 'アラート発出件数 - 眠気'
    case UserGroupConfigKey.isHyperthermiaAlertCountEnabled:
      return 'アラート発出件数 - 体調指数'
    case UserGroupConfigKey.isStressAlertCountEnabled:
      return 'アラート発出件数 - ストレス'
  }
}

export type UserGroupConfig = { [key in UserGroupConfigKey]: { [key in Platform]: string } }

// Model -------------

export default class UserGroup {
  id: number
  code: string
  name: string
  availableFrom: moment.Moment | null
  availableTo: moment.Moment | null
  configs?: UserGroupConfig
  createdAt: moment.Moment

  get availablePeriod(): string {
    let period = ''

    if (!this.availableFrom && !this.availableTo) return '制限なし'

    if (this.availableFrom) {
      period += formatDate(this.availableFrom)
    }

    period += ' 〜 '

    if (this.availableTo) {
      period += formatDate(this.availableTo)
    }

    return period
  }

  get createdDate(): string {
    return formatDateTime(this.createdAt)
  }

  constructor(
    id: number,
    code: string,
    name: string,
    availableFrom: moment.Moment | null,
    availableTo: moment.Moment | null,
    configs: UserGroupConfig | undefined,
    createdAt: moment.Moment,
  ) {
    this.id = id
    this.code = code
    this.name = name
    this.availableFrom = availableFrom
    this.availableTo = availableTo
    this.configs = configs
    this.createdAt = createdAt
  }

  static load(item: UserGroupData): UserGroup {
    return new UserGroup(
      item.id,
      item.code,
      item.name,
      (item.availableFrom && moment(item.availableFrom)) || null,
      (item.availableTo && moment(item.availableTo)) || null,
      item.configs,
      moment(item.createdAt),
    )
  }

  static loadAll(data: UserGroupData[]): UserGroup[] {
    return data.map((item) => this.load(item))
  }
}
