import { channel } from 'redux-saga'
import { put, fork, takeEvery, take } from 'redux-saga/effects'

import apiClient, { UserData } from 'utils/apiClient'
import * as Auth from 'redux/modules/auth'
import User from 'redux/models/user'

type LogInAction = ReturnType<typeof Auth.actions.logIn>
type ChangePasswordAction = ReturnType<typeof Auth.actions.changePassword>
type ResetPasswordAction = ReturnType<typeof Auth.actions.resetPassword>

const redirectChannel = channel()

const loadAuth = function* () {
  yield take(Auth.LOAD)

  try {
    const data: UserData = yield apiClient.getUser('myself')
    const user = User.load(data)
    yield put(Auth.actions.syncAuth(user))
  } catch (err) {
    console.error(err)
    yield put(Auth.actions.syncAuth(null))
  }
}

const logIn = function* (action: LogInAction) {
  try {
    const { groupCode, userCode, password } = action.payload
    const userName = `${groupCode}_${userCode}`
    const data: UserData = yield apiClient.logIn(userName, password)
    const user = User.load(data)
    yield put(Auth.actions.succeededToLogIn(user))
  } catch (err) {
    console.error(err)
    yield put(Auth.actions.failedToLogIn('ログインできませんでした'))
  }
}

const logOut = function* () {
  yield apiClient.logOut()
  yield put(Auth.actions.succeededToLogOut())
}

const changePssword = function* (action: ChangePasswordAction) {
  try {
    const { currentPassword, newPassword } = action.payload
    yield apiClient.changePassword(currentPassword, newPassword)
    yield put(Auth.actions.succeededToChangePassword())
  } catch (err) {
    console.error(err)
    yield put(Auth.actions.failedToChangePassword('パスワードを変更できませんでした'))
  }
}

const resetPassword = function* (action: ResetPasswordAction) {
  //
}

export default function* dataSaga() {
  yield fork(loadAuth)

  yield takeEvery(Auth.LOGIN, logIn)
  yield takeEvery(Auth.LOGOUT, logOut)
  yield takeEvery(Auth.CHANGE_PASSWORD, changePssword)
  yield takeEvery(Auth.RESET_PASSWORD, resetPassword)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
