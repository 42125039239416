import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import User from 'redux/models/user'

import {
  AdminCompanies,
  AdminEditCompany,
  AdminEditUser,
  AdminNewCompany,
  AdminNewUser,
  AdminUsers,
  AppTemplate,
  Dashboard,
  EditPassword,
  Login,
  Notifications,
  NotificationsMap,
  Observations,
  ObservationRequests,
  UsersAlertActions,
  UsersGraphs,
} from 'components'

interface CommonRouterProps {
  auth: AuthState
}

const Auth = (props: React.PropsWithChildren<{ user: User | null }>) => {
  return props.user !== null ? <>{props.children}</> : <Redirect to="/login" />
}

class CommonRouter extends React.Component<CommonRouterProps> {
  render() {
    const { user } = this.props.auth

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />

          <Auth user={user}>
            <AppTemplate>
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/notifications" component={Notifications} />
                <Route exact path="/notifications/map" component={NotificationsMap} />
                <Route exact path="/observations/" component={Observations} />
                <Route exact path="/observations/requests" component={ObservationRequests} />
                <Route exact path={`/users/${user && user.id}/password`} component={EditPassword} />
                <Route exact path="/users/:id/alert_actions" component={UsersAlertActions} />
                <Route exact path="/users/:id/graphs" component={UsersGraphs} />

                {user?.isAdmin && (
                  <Switch>
                    <Route exact path="/admin/companies" component={AdminCompanies} />
                    <Route exact path="/admin/companies/new" component={AdminNewCompany} />
                    <Route exact path="/admin/companies/:id/edit" component={AdminEditCompany} />
                    <Route exact path="/admin/users" component={AdminUsers} />
                    <Route exact path="/admin/users/new" component={AdminNewUser} />
                    <Route exact path="/admin/users/:id/edit" component={AdminEditUser} />
                    <Redirect to="/" />
                  </Switch>
                )}

                <Redirect to="/" />
              </Switch>
            </AppTemplate>
          </Auth>
        </Switch>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state: AppState): CommonRouterProps => ({
  auth: state.auth,
})

export default connect(mapStateToProps, {})(CommonRouter)
