import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { EditUserStoreStates, EditUserActions } from '.'
import validator from 'utils/validator'

import { Grid, Typography } from '@material-ui/core'
import {
  MainContainer,
  SectionHeader,
  FormContainer,
  Form,
  SelectForm,
  GreenButton,
} from 'components'

type EditUserProps = RouteComponentProps<{ id: string }> & EditUserStoreStates & EditUserActions

interface EditUserStates {
  initialization: boolean
  userGroupId: number
  name: string
  code: string
  password: string
}

class EditUser extends React.Component<EditUserProps, EditUserStates> {
  constructor(props: EditUserProps) {
    super(props)

    this.state = {
      initialization: false,
      userGroupId: 0,
      name: '',
      code: '',
      password: '',
    }

    props.fetchUserGroupList()
    props.fetchUserList()
  }

  get user() {
    const { match, users } = this.props
    const userId = parseInt(match.params.id)
    return users.items.find((item) => item.id === userId)
  }

  componentDidUpdate(prevProps: EditUserProps) {
    const { initialization } = this.state
    const { history, users } = this.props
    const currentValue = users.update.updating
    const prevValue = prevProps.users.update.updating
    const user = this.user

    if (prevValue && !currentValue && !users.update.error) {
      // 更新が正常終了したら戻る
      history.goBack()
    }

    if (!initialization && user) {
      setTimeout(
        () =>
          this.setState({
            initialization: true,
            userGroupId: user.userGroup.id,
            name: user.name,
            code: user.code,
          }),
        200,
      )
    }
  }

  onSubmit() {
    const { userGroupId, name, code, password } = this.state
    const { userGroups } = this.props
    const userGroupIds = userGroups.items.map((i) => i.id)
    const user = this.user

    if (!user) return

    try {
      validator.selection(userGroupId, userGroupIds, '企業')
      validator.notEmpty(name, code)
      validator.userCode(code)
      if (password) validator.password(password)
    } catch (err) {
      alert(err)
      return
    }

    this.props.updateUser({
      ...this.state,
      password: (!!password && password) || undefined, // 空なら更新しない
      user,
    })
  }

  render() {
    const { userGroupId, name, code, password } = this.state
    const { users, userGroups } = this.props
    const { updating, error } = users.update
    const isEmpty = userGroupId === 0 || [name, code].includes('')

    const options = [{ label: '企業名', value: 0 }].concat(
      userGroups.items.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    )

    if (!this.user) return <></>

    return (
      <MainContainer>
        <SectionHeader>ユーザー編集</SectionHeader>
        <FormContainer>
          <SelectForm
            value={`${userGroupId}`}
            label="所属企業"
            options={options}
            onChange={(value) => this.setState({ userGroupId: parseInt(value) })}
          />
          <Form
            value={code}
            label="ユーザーID"
            caption="変更すると所属ユーザーのセッションが切れます。"
            onChange={(code) => this.setState({ code })}
          />
          <Form value={name} label="ユーザー名" onChange={(name) => this.setState({ name })} />
          <Form
            value={password}
            label="初期パスワード"
            caption="変更する場合のみ入力してください。（ユーザが一度もログインしていない場合のみ有効）"
            type="password"
            onChange={(password) => this.setState({ password })}
          />

          <Grid container direction="column" alignItems="flex-end" spacing={1}>
            {error && (
              <Grid>
                <Typography color="secondary">{error}</Typography>
              </Grid>
            )}
            <Grid item>
              <GreenButton
                disabled={isEmpty || updating}
                variant="contained"
                title={updating ? '更新中' : '更新する'}
                onClick={() => this.onSubmit()}
              />
            </Grid>
          </Grid>
        </FormContainer>
      </MainContainer>
    )
  }
}

export default withRouter(EditUser)
