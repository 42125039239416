import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import {
  actions as userGroupsActions,
  UserGroupsState,
  AddUserGroupPayload,
} from 'redux/modules/userGroups'
import Screen from './NewCompany'

export interface NewCompanyActions {
  addCompany: (payload: AddUserGroupPayload) => Action<AddUserGroupPayload>
}

export interface NewCompanyStoreStates {
  userGroups: UserGroupsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): NewCompanyActions {
  return {
    addCompany: (payload) => dispatch(userGroupsActions.addUserGroup(payload)),
  }
}

function mapStateToProps(appState: AppState): NewCompanyStoreStates {
  return { userGroups: appState.userGroups }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
