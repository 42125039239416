import React from 'react'
import moment from 'moment'
import { Button, withStyles, WithStyles, Grid, Typography } from '@material-ui/core'

import { SERVICE_LAUNCHED_AT } from 'utils/Constants'
import CalendarButton from './CalendarButton'
import { dayStyles } from './styles'

type DaysProps = WithStyles<typeof dayStyles> & {
  activeDate: moment.Moment
  onChange: (from: moment.Moment, to: moment.Moment) => void
}

interface DaysStates {
  offset: number
}

class Days extends React.Component<DaysProps, DaysStates> {
  constructor(props: DaysProps) {
    super(props)
    this.state = { offset: 0 }
  }

  render() {
    const { offset } = this.state
    const { classes, activeDate, onChange } = this.props

    const launchedDate = moment(SERVICE_LAUNCHED_AT).startOf('day')
    const baseDate = moment(activeDate).startOf('day')
    const offsetDate = moment(activeDate).startOf('month').add(offset, 'month')
    const months = [offsetDate, moment(offsetDate).add(1, 'month')]

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item container direction="row" alignItems="center">
          <Button
            className={classes.slideButton}
            disabled={offsetDate.isBefore(launchedDate)}
            onClick={() => this.setState({ offset: offset - 1 })}>
            ＜
          </Button>
          {months.map((month, index) => (
            <Grid item xs key={index}>
              <Typography align="center">{month.format('YYYY年MM月')}</Typography>
            </Grid>
          ))}
          <Button
            className={classes.slideButton}
            disabled={offsetDate.isSameOrAfter(moment().startOf('month'))}
            onClick={() => this.setState({ offset: offset + 1 })}>
            ＞
          </Button>
        </Grid>
        <Grid container direction="row" spacing={2}>
          {months.map((month, mi) => {
            const date = moment(month).startOf('isoWeek')
            const weeks = [...Array(6)].map((_, wi) => {
              return [...Array(7)].map((_, di) => {
                const day = moment(date).add(wi, 'week').add(di, 'day')
                return day.month() === month.month() ? day : null
              })
            })

            return (
              <Grid item xs container direction="column" key={mi}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  className={classes.weekdayContainer}>
                  {['月', '火', '水', '木', '金', '土', '日'].map((label, index) => (
                    <Grid item xs key={index} className={classes.labelContainer}>
                      <Typography align="center" className={classes.weekdayLabel}>
                        {label}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                {weeks.map((week, wi) => (
                  <Grid
                    item
                    xs
                    container
                    direction="row"
                    justify="space-between"
                    key={wi}
                    className={classes.weekContainer}>
                    {week.map((day, di) =>
                      day ? (
                        <Grid item xs key={di}>
                          <CalendarButton
                            title={day?.format('D')}
                            className={classes.button}
                            selected={day.isSame(baseDate)}
                            disabled={day.isBefore(launchedDate) || day.isAfter(moment())}
                            onClick={() => onChange(day, moment(day).endOf('day'))}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs key={di} className={classes.button} />
                      ),
                    )}
                  </Grid>
                ))}
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(dayStyles)(Days)
