import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'

import { UsersAlertActionsStoreStates, UsersAlertActionsActions } from '.'

import { MainContainer, SectionHeader, Table } from 'components'

type UsersAlertActionsProps = RouteComponentProps<{ id: string }> &
  UsersAlertActionsStoreStates &
  UsersAlertActionsActions

class UsersAlertActions extends React.Component<UsersAlertActionsProps> {
  constructor(props: UsersAlertActionsProps) {
    super(props)

    const { match } = props
    const userId = parseInt(match.params.id)
    props.fetchAlertActions(userId)
  }

  render() {
    const { alerts } = this.props
    const { fetching, items } = alerts
    const userName = items.length > 0 && items[0].user.name

    const headers = ['日時', 'タイプ', 'メッセージ']
    const rows = items.map((item) => [item.createdDate, item.typeText, item.message])

    return (
      <MainContainer headerItem={<ClipLoader size={20} color={'#123abc'} loading={fetching} />}>
        <SectionHeader>{userName && `${userName}への`}アクション履歴</SectionHeader>
        <Table fetched headers={headers} rows={rows} />
      </MainContainer>
    )
  }
}

export default withRouter(UsersAlertActions)
