import { createStyles, Theme } from '@material-ui/core'
import { SideBarColor } from 'utils/Color'

export const drawerWidth = 256

export const templateStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: 1,
      padding: theme.spacing(3, 2),
    },
  })

export const navigationStyles = (theme: Theme) =>
  createStyles({
    logoContainer: {},
    logo: {
      margin: '0 auto',
    },
    categoryHeader: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${SideBarColor.BoxUnderLine}`,
    },
    categoryHeaderPrimary: {
      color: SideBarColor.headerText,
      fontSize: 15,
      fontWeight: 'bold',
    },

    item: {
      height: 50,
      paddingTop: 1,
      paddingBottom: 1,
      color: theme.palette.common.white,
      borderBottom: `1px solid ${SideBarColor.BoxUnderLine}`,

      '&:hover,&:focus': {
        backgroundColor: SideBarColor.BoxActive,
      },
    },
    activeItem: {
      color: SideBarColor.BoxActiveText,
      backgroundColor: SideBarColor.BoxActive,
      borderLeft: `3px solid ${SideBarColor.BoxActiveText}`,
    },
    itemPrimary: {
      color: theme.palette.common.white,
      fontSize: 15,
    },
    activeItemPrimary: {
      color: SideBarColor.BoxActiveText,
      fontSize: 15,
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
  })
