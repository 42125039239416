import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import {
  actions as userGroupsActions,
  UserGroupsState,
  DeleteUserGroupPayload,
} from 'redux/modules/userGroups'
import Screen from './Companies'

export interface CompaniesActions {
  fetchList: () => Action<void>
  deleteCompany: (payload: DeleteUserGroupPayload) => Action<DeleteUserGroupPayload>
}

export interface CompaniesStoreStates {
  userGroups: UserGroupsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): CompaniesActions {
  return {
    fetchList: () => dispatch(userGroupsActions.fetchList()),
    deleteCompany: (payload) => dispatch(userGroupsActions.deleteUserGroup(payload)),
  }
}

function mapStateToProps(appState: AppState): CompaniesStoreStates {
  return { userGroups: appState.userGroups }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
