import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import User from 'redux/models/user'

// Actions
export const FETCH_LIST = 'hamon/users/FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'hamon/users/FETCH_LIST_SUCCESS'
export const FETCH_LIST_FAILURE = 'hamon/users/FETCH_LIST_FAILURE'
export const IMPORT_NEW_USER_DATA = 'hamon/users/IMPORT_NEW_USER_DATA'
export const IMPORT_NEW_USER_DATA_COMPLETION = 'hamon/users/IMPORT_NEW_USER_DATA_COMPLETION'
export const ADD_USERS = 'hamon/users/ADD_USERS'
export const ADD_USERS_SUCCESS = 'hamon/users/ADD_USERS_SUCCESS'
export const ADD_USERS_FAILURE = 'hamon/users/ADD_USERS_FAILURE'
export const UPDATE_USER = 'hamon/users/UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'hamon/users/UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'hamon/users/UPDATE_USER_FAILURE'
export const DELETE_USER = 'hamon/users/DELETE_USER'
export const DELETE_USER_SUCCESS = 'hamon/users/DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'hamon/users/DELETE_USER_FAILURE'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface ImportNewUserDataResultPayload {
  users: {
    userGroupId: number
    code: string
    name: string
    password: string
  }[]
  errors: {
    row: string
    message: string
  }[]
}

export interface AddUsersPayload {
  users: {
    userGroupId: number
    code: string
    name: string
    password: string
  }[]
}

export interface UpdateUserPayload {
  user: User
  userGroupId: number
  code: string
  name: string
  password?: string
}

export const actions = {
  fetchList: actionCreator(FETCH_LIST),
  succeededToFetchList: actionCreator<User[]>(FETCH_LIST_SUCCESS),
  failedToFetchList: actionCreator<string>(FETCH_LIST_FAILURE),
  importNewUserData: actionCreator<File>(IMPORT_NEW_USER_DATA),
  completedToImportNewUserData: actionCreator<ImportNewUserDataResultPayload>(
    IMPORT_NEW_USER_DATA_COMPLETION,
  ),
  addUsers: actionCreator<AddUsersPayload>(ADD_USERS),
  succeededToAddUsers: actionCreator<User[]>(ADD_USERS_SUCCESS),
  failedToAddUsers: actionCreator<string>(ADD_USERS_FAILURE),
  updateUser: actionCreator<UpdateUserPayload>(UPDATE_USER),
  succeededToUpdateUser: actionCreator<User>(UPDATE_USER_SUCCESS),
  failedToUpdateUser: actionCreator<string>(UPDATE_USER_FAILURE),
  deleteUser: actionCreator<User>(DELETE_USER),
  succeededToDeleteUser: actionCreator<User>(DELETE_USER_SUCCESS),
  failedToDeleteUser: actionCreator<string>(DELETE_USER_FAILURE),
}

// Reducer
export interface UsersState {
  items: User[]
  fetchingList: boolean
  fetchListError: string | null
  add: {
    importData: ImportNewUserDataResultPayload | null
    adding: boolean
    error: string | null
  }
  update: {
    updating: boolean
    error: string | null
  }
  delete: {
    deleting: boolean
    error: string | null
  }
}

const initialState: () => UsersState = () => ({
  items: [],
  fetchingList: false,
  fetchListError: null,
  add: {
    importData: null,
    adding: false,
    error: null,
  },
  update: {
    updating: false,
    error: null,
  },
  delete: {
    deleting: false,
    error: null,
  },
})

export default reducerWithInitialState(initialState())
  .case(actions.fetchList, (state) => ({
    ...state,
    fetchingList: true,
    fetchListError: null,
  }))
  .case(actions.succeededToFetchList, (state, items) => ({
    ...state,
    items,
    fetchingList: false,
    fetchListError: null,
  }))
  .case(actions.failedToFetchList, (state, error) => ({
    ...state,
    items: [],
    fetchingList: false,
    fetchListError: error,
  }))
  .case(actions.importNewUserData, (state) => ({
    ...state,
    add: {
      ...state.add,
      importData: null,
    },
  }))
  .case(actions.completedToImportNewUserData, (state, data) => ({
    ...state,
    add: {
      ...state.add,
      importData: data,
    },
  }))
  .case(actions.addUsers, (state) => {
    const add = state.add
    return {
      ...state,
      add: {
        ...add,
        adding: true,
        error: null,
      },
    }
  })
  .case(actions.succeededToAddUsers, (state, item) => {
    const items = state.items.concat(item)
    const add = state.add
    return {
      ...state,
      items,
      add: {
        ...add,
        adding: false,
        error: null,
      },
    }
  })
  .case(actions.failedToAddUsers, (state, error) => {
    const add = state.add
    return {
      ...state,
      add: {
        ...add,
        adding: false,
        error,
      },
    }
  })
  .case(actions.updateUser, (state) => {
    const update = state.update
    return {
      ...state,
      update: {
        ...update,
        updating: true,
        error: null,
      },
    }
  })
  .case(actions.succeededToUpdateUser, (state, item) => {
    const update = state.update
    const items = [...state.items]
    const index = items.findIndex((i) => i.id === item.id)
    items[index] = item

    return {
      ...state,
      items,
      update: {
        ...update,
        updating: false,
        error: null,
      },
    }
  })
  .case(actions.failedToUpdateUser, (state, error) => {
    const update = state.update
    return {
      ...state,
      update: {
        ...update,
        updating: false,
        error,
      },
    }
  })
  .case(actions.deleteUser, (state) => {
    const current = state.delete
    return {
      ...state,
      delete: {
        ...current,
        deleting: true,
        error: null,
      },
    }
  })
  .case(actions.succeededToDeleteUser, (state, item) => {
    const current = state.delete
    const items = state.items.filter((i) => i.id !== item.id)

    return {
      ...state,
      items,
      delete: {
        ...current,
        deleting: false,
        error: null,
      },
    }
  })
  .case(actions.failedToDeleteUser, (state, error) => {
    const current = state.delete
    return {
      ...state,
      delete: {
        ...current,
        deleting: false,
        error,
      },
    }
  })
