import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as notificationsActions, NotificationsState } from 'redux/modules/notifications'
import Screen from './Notifications'

export interface NotificationsActions {
  fetchNotifications: (page: number) => Action<number>
}

export interface NotificationsStoreStates {
  notifications: NotificationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): NotificationsActions {
  return {
    fetchNotifications: (page) => dispatch(notificationsActions.fetchNotifications(page)),
  }
}

function mapStateToProps(appState: AppState): NotificationsStoreStates {
  return { notifications: appState.notifications }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
