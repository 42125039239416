import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  WithStyles,
  withStyles,
  Typography,
  Grid,
  TableCell,
  CircularProgress,
} from '@material-ui/core'
import { DeleteForever } from '@material-ui/icons'

import UserGroup from 'redux/models/userGroup'
import { CompaniesActions, CompaniesStoreStates } from '.'

import { MainContainer, Table, SectionHeader, Form, FormContainer, BlueButton } from 'components'
import { AccessoryCell } from 'components/Table'
import styles from './styles'

type CompaniesProps = WithStyles<typeof styles> &
  RouteComponentProps &
  CompaniesActions &
  CompaniesStoreStates

interface CompaniesStates {
  groupName: string
  groupCode: string
}

class Companies extends React.Component<CompaniesProps, CompaniesStates> {
  constructor(props: CompaniesProps) {
    super(props)

    this.state = {
      groupName: '',
      groupCode: '',
    }

    props.fetchList()
  }

  checkDelete(group: UserGroup, physics: boolean) {
    if (!window.confirm(`${group.name}を削除してよろしいですか？`)) return
    const message = `[${group.name}]\nデータは完全に削除され、復元できなくなりますがよろしいですか？`
    if (physics && !window.confirm(message)) return
    this.props.deleteCompany({ group, physics })
  }

  renderPhysicsDeleteCell(item: UserGroup) {
    const { classes } = this.props

    return (
      <TableCell key={`reset-${item.id}`} className={classes.physicsDeelteCell}>
        <Button className={classes.cellButton} onClick={() => this.checkDelete(item, true)}>
          <Grid container direction="column">
            <Grid>
              <DeleteForever fontSize="large" />
            </Grid>
            <Grid>
              <Typography className={classes.cellButtonText}>完全削除</Typography>
            </Grid>
          </Grid>
        </Button>
      </TableCell>
    )
  }

  render() {
    const { groupName, groupCode } = this.state
    const { classes, history, userGroups, fetchList } = this.props
    const { items, fetchingList, fetchListError } = userGroups

    if (userGroups.delete.deleting) {
      return (
        <MainContainer>
          <Grid container alignItems="center" justify="center" className={classes.loadingContainer}>
            <CircularProgress />
          </Grid>
        </MainContainer>
      )
    }

    const filteredItems = items.filter(
      (item) => item.name.includes(groupName) && item.code.includes(groupCode),
    )

    const rows = filteredItems.map((item) => [
      item.name,
      item.code,
      item.availablePeriod,
      item.createdDate,
    ])
    const accessories: AccessoryCell[][] = filteredItems.map((item) => [
      'edit',
      'delete',
      this.renderPhysicsDeleteCell(item),
    ])

    return (
      <MainContainer
        headerItem={
          <Button variant="contained" onClick={() => fetchList()}>
            {fetchingList ? '更新中' : '更新する'}
          </Button>
        }>
        <Grid container direction="row" justify="flex-end">
          <BlueButton
            variant="contained"
            title="新規企業登録"
            onClick={() => history.push('/admin/companies/new')}
          />
        </Grid>

        <SectionHeader>絞り込み</SectionHeader>

        <FormContainer>
          <Form
            value={groupName}
            label="企業名"
            onChange={(groupName) => this.setState({ groupName })}
          />
          <Form
            value={groupCode}
            label="企業コード"
            onChange={(groupCode) => this.setState({ groupCode })}
          />
        </FormContainer>

        <SectionHeader>検索結果</SectionHeader>
        {fetchListError && <Typography>{fetchListError}</Typography>}

        <Table
          fetched
          headers={['企業名', '企業コード', '利用可能期間', '登録日時', '', '']}
          rows={rows}
          accessories={accessories}
          onEdit={(index) => history.push(`/admin/companies/${filteredItems[index].id}/edit`)}
          onDelete={(index) => this.checkDelete(filteredItems[index], false)}
        />
      </MainContainer>
    )
  }
}

export default withStyles(styles)(Companies)
