import React from 'react'
import { RouteComponentProps } from 'react-router'
import clsx from 'clsx'

import Observation from 'redux/models/observation'
import { ObservationRequestsActions, ObservationRequestsStoreStates } from '.'

import { TableCell, Button, Grid, Typography, WithStyles, withStyles } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import { MainContainer, SectionHeader, FormContainer, Form, Table } from 'components'
import { AccessoryCell } from 'components/Table'

import styles from './styles'

type ObservationRequestsProps = RouteComponentProps &
  WithStyles<typeof styles> &
  ObservationRequestsActions &
  ObservationRequestsStoreStates

interface ObservationRequestsStates {
  name: string
  code: string
}

class ObservationRequest extends React.Component<
  ObservationRequestsProps,
  ObservationRequestsStates
> {
  constructor(props: ObservationRequestsProps) {
    super(props)

    this.state = {
      name: '',
      code: '',
    }

    props.fetchObservationRequests()
  }

  componentDidUpdate(prevProps: ObservationRequestsProps) {
    const { observations, fetchObservationRequests } = this.props
    const current = observations.answer
    const previous = prevProps.observations.answer

    if (previous.observation && !current.observation) {
      fetchObservationRequests()

      // 申請の回答が終了したらアラート
      alert(current.error || `${previous.observation.name}の申請への回答を送信しました`)
    }
  }

  onClickAction(observation: Observation, isApproved: boolean) {
    this.props.sendAnsewr({ observation, isApproved })
  }

  renderApproveCell(item: Observation) {
    const { classes } = this.props

    return (
      <TableCell key={`approve-${item.id}`} className={clsx(classes.cell, classes.approveCell)}>
        <Button className={classes.cellButton} onClick={() => this.onClickAction(item, true)}>
          <Grid container direction="column">
            <Grid>
              <Check fontSize="large" />
            </Grid>
            <Grid>
              <Typography className={classes.cellButtonText}>承認</Typography>
            </Grid>
          </Grid>
        </Button>
      </TableCell>
    )
  }

  renderRejectCell(item: Observation) {
    const { classes } = this.props

    return (
      <TableCell key={`reject-${item.id}`} className={clsx(classes.cell, classes.rejectCell)}>
        <Button className={classes.cellButton} onClick={() => this.onClickAction(item, false)}>
          <Grid container direction="column">
            <Grid>
              <Close fontSize="large" />
            </Grid>
            <Grid>
              <Typography className={classes.cellButtonText}>拒否</Typography>
            </Grid>
          </Grid>
        </Button>
      </TableCell>
    )
  }

  render() {
    const { name, code } = this.state
    const { observations } = this.props
    const { items, fetched, fetching } = observations.observationRequests

    if (fetching || !fetched) return <></>

    const filteredItems = items.filter(
      (item) => item.name.includes(name) && item.code.includes(code),
    )
    const rows = filteredItems.map((item) => [item.name, item.code, item.createdDate])
    const accessories: AccessoryCell[][] = filteredItems.map((item) => [
      this.renderApproveCell(item),
      this.renderRejectCell(item),
    ])

    return (
      <MainContainer>
        <SectionHeader>絞り込み</SectionHeader>

        <FormContainer>
          <Form value={name} label="名前" onChange={(name) => this.setState({ name })} />
          <Form value={code} label="ユーザーID" onChange={(code) => this.setState({ code })} />
        </FormContainer>

        <SectionHeader>検索結果</SectionHeader>

        <Table
          fetched
          headers={['名前', 'ユーザID', '登録日時', '', '']}
          rows={rows}
          accessories={accessories}
        />
        {/* <LightGrayText style={{ marginBottom: 10 }} size={16} bold>
          絞り込み
        </LightGrayText>
        <Content>
          <Form>
            <FormTitle>名前：</FormTitle>
            <UnderlineInput
              style={{ width: 200 }}
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </Form>
          <Form>
            <FormTitle>ユーザーID：</FormTitle>
            <UnderlineInput
              style={{ width: 200 }}
              value={code}
              onChange={(e) => this.setState({ code: e.target.value })}
            />
          </Form>
        </Content>
        <LightGrayText style={{ marginTop: 30 }} size={16} bold>
          検索結果
        </LightGrayText>
        <FlexRow style={{ justifyContent: 'space-between', marginTop: 20, width: '100%' }}>
          <TitleBox style={{ width: LongBoxWidth + 20 }}>
            <LightGrayText bold>名前</LightGrayText>
          </TitleBox>
          <TitleBox style={{ width: LongBoxWidth + 20 }}>
            <LightGrayText bold>ユーザーID</LightGrayText>
          </TitleBox>
          <TitleBox style={{ width: LongBoxWidth + 20 }}>
            <LightGrayText bold>登録日時</LightGrayText>
          </TitleBox>
          <TitleBox style={{ width: ShortBoxWidth + 20 }} />
          <TitleBox style={{ width: ShortBoxWidth + 20 }} />
        </FlexRow>
        {filterdItems.map((item) => (
          <FlexRow
            key={item.id}
            style={{ justifyContent: 'space-between', marginTop: 15, width: '100%' }}>
            <WhiteBox>
              <BlackText bold>{item.name}</BlackText>
            </WhiteBox>
            <WhiteBox>
              <BlackText bold>{item.code}</BlackText>
            </WhiteBox>
            <WhiteBox>
              <BlackText bold>{item.createdDate}</BlackText>
            </WhiteBox>
            <WhiteBox
              style={{
                maxWidth: ShortBoxWidth,
                backgroundColor: Color.Blue,
              }}>
              <FlexCol
                style={{ height: '80%', justifyContent: 'space-around', alignItems: 'center' }}
                onClick={() => this.onClickAction(item, true)}>
                <Icon src={UserAdd} size={30} />
                <WhiteText size={12} bold>
                  承認する
                </WhiteText>
              </FlexCol>
            </WhiteBox>
            <WhiteBox
              style={{
                maxWidth: ShortBoxWidth,
                backgroundColor: Color.Red,
              }}>
              <FlexCol
                style={{ height: '80%', justifyContent: 'space-around', alignItems: 'center' }}
                onClick={() => this.onClickAction(item, false)}>
                <Icon src={UserAdd} size={30} />
                <WhiteText size={12} bold>
                  拒否する
                </WhiteText>
              </FlexCol>
            </WhiteBox>
          </FlexRow>
        ))} */}
      </MainContainer>
    )
  }
}

export default withStyles(styles)(ObservationRequest)
