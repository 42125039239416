import React from 'react'
import { WithStyles, withStyles, Grid, Container } from '@material-ui/core'

import { ContainerStoreStates, ContainerActions } from '.'
import styles from './styles'
import Header from './Header'
import Copyright from './Copyright'

type ContainerProps = React.PropsWithChildren<{}> &
  WithStyles<typeof styles> &
  ContainerStoreStates &
  ContainerActions & {
    headerItem?: React.ReactElement
  }

class MainContainer extends React.Component<ContainerProps> {
  render() {
    const { classes, children, auth, headerItem, logOut } = this.props
    const { user } = auth

    if (!user) return <></>

    return (
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Header
            className={classes.header}
            user={user}
            headerItem={headerItem}
            onLogOut={() => logOut()}
          />
        </Grid>
        <Grid item xs className={classes.content}>
          <Container className={classes.mainContainer}>
            <main className={classes.main}>{children}</main>
          </Container>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(MainContainer)
