import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState, ChangePasswordPayload } from 'redux/modules/auth'
import Screen from './EditPassword'

export interface EditPasswordActions {
  changePassword: (payload: ChangePasswordPayload) => Action<ChangePasswordPayload>
}

export interface EditPasswordStoreStates {
  auth: AuthState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): EditPasswordActions {
  return {
    changePassword: (payload) => dispatch(authActions.changePassword(payload)),
  }
}

function mapStateToProps(appState: AppState): EditPasswordStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
