import { fork } from 'redux-saga/effects'
import { default as alerts } from './alerts'
import { default as auth } from './auth'
import { default as graphs } from './graphs'
import { default as notifications } from './notifications'
import { default as observations } from './observations'
import { default as userGroups } from './userGroups'
import { default as users } from './users'

export default function* () {
  yield fork(alerts)
  yield fork(auth)
  yield fork(graphs)
  yield fork(notifications)
  yield fork(observations)
  yield fork(userGroups)
  yield fork(users)
}
