import React from 'react'
import {
  Dashboard,
  DashboardOutlined,
  NotificationsActive,
  NotificationsActiveOutlined,
  Map,
  MapOutlined,
  Description,
  DescriptionOutlined,
  AssignmentTurnedIn,
  AssignmentTurnedInOutlined,
  Settings,
  SettingsOutlined,
  BusinessTwoTone,
  BusinessOutlined,
  People,
  PeopleOutline,
} from '@material-ui/icons'

const makeIcon = (icon: React.ReactElement, aIcon: React.ReactElement) => (isActive: boolean) => {
  return isActive ? aIcon : icon
}

export interface MenuItem {
  path: string
  pattern: RegExp
  title: string
  showMenu: boolean
  icon: (active: boolean) => React.ReactElement
  children: {
    pattern: RegExp
    title: string
  }[]
}

export default [
  {
    title: 'メインメニュー',
    onlyAdmin: false,
    items: [
      {
        path: '/',
        pattern: /^\/$/,
        title: 'ダッシュボード',
        showMenu: true,
        icon: makeIcon(<DashboardOutlined />, <Dashboard />),
        children: [{ pattern: /^\/$/, title: 'ユーザ一覧' }],
      },
      {
        path: '/notifications',
        pattern: /^\/notifications$/,
        title: '通知履歴',
        showMenu: true,
        icon: makeIcon(<NotificationsActiveOutlined />, <NotificationsActive />),
        children: [],
      },
      {
        path: '/notifications/map',
        pattern: /^\/notifications\/map/,
        title: '通知マップ',
        showMenu: true,
        icon: makeIcon(<MapOutlined />, <Map />),
        children: [],
      },
      {
        path: '/observations',
        pattern: /^\/observations\/?$/,
        title: 'バイタル閲覧申請',
        showMenu: true,
        icon: makeIcon(<DescriptionOutlined />, <Description />),
        children: [],
      },
      {
        path: '/observations/requests',
        pattern: /^\/observations\/requests/,
        title: 'バイタル閲覧承認',
        showMenu: true,
        icon: makeIcon(<AssignmentTurnedInOutlined />, <AssignmentTurnedIn />),
        children: [],
      },
      {
        path: '/users/:userId/password',
        pattern: /^\/users\/\d+\/password/,
        title: 'パスワード設定',
        showMenu: true,
        icon: makeIcon(<SettingsOutlined />, <Settings />),
        children: [],
      },
      {
        path: '/users/:id/alert_acitons',
        pattern: /^\/users\/\d+\/alert_actions/,
        title: 'アクション履歴',
        showMenu: false,
        icon: makeIcon(<SettingsOutlined />, <Settings />),
        children: [],
      },
      {
        path: '/users/:id/graphs',
        pattern: /^\/users\/\d+\/graphs/,
        title: 'グラフ',
        showMenu: false,
        icon: makeIcon(<SettingsOutlined />, <Settings />),
        children: [],
      },
    ],
  },
  {
    title: 'データ管理',
    onlyAdmin: true,
    items: [
      {
        path: '/admin/companies',
        pattern: /^\/admin\/companies/,
        title: '企業管理',
        showMenu: true,
        icon: makeIcon(<BusinessOutlined />, <BusinessTwoTone />),
        children: [
          { pattern: /^\/admin\/companies\/?$/, title: '企業一覧' },
          { pattern: /new/, title: '企業登録' },
          { pattern: /edit/, title: '企業編集' },
        ],
      },
      {
        path: '/admin/users',
        pattern: /^\/admin\/users/,
        title: 'ユーザ管理',
        showMenu: true,
        icon: makeIcon(<PeopleOutline />, <People />),
        children: [
          { pattern: /new/, title: 'ユーザ登録' },
          { pattern: /edit/, title: 'ユーザ編集' },
        ],
      },
    ],
  },
] as {
  title: string
  onlyAdmin: boolean
  items: MenuItem[]
}[]
