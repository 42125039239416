import React, { PropsWithChildren } from 'react'

type PinProps = PropsWithChildren<{
  lat: number
  lng: number
}>

const Pin = (props: PinProps) => {
  const { children, lat, lng } = props
  const pinProps: unknown = { lat, lng }
  return <div {...pinProps}>{children}</div>
}

export default Pin
