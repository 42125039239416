/* eslint @typescript-eslint/no-non-null-assertion: 0 */
import moment from 'moment'
import { Environment } from 'utils/environment'

const environments = Object.values(Environment) as string[]

export const ENV = environments.includes(process.env.NODE_ENV)
  ? (process.env.NODE_ENV as Environment)
  : Environment.Development

export const API_URL = process.env.REACT_APP_API_URL!

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!

export const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID!
export const AWS_USER_POOL_APP_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOL_APP_CLIENT_ID!

export const SERVICE_LAUNCHED_AT = moment(process.env.REACT_APP_SERVICE_LAUNCHED_AT)

export enum COOKIE_KEYS {
  NOTIFICATIONS_MAP = "hamon2_notifications_map",
}

export const COOKIE_EXPIREDAYS = 365

export const NOTIFICATIONS_MAP_ERROR_MSG = [
  "住所が判別できませんでした。住所を変更して頂くか、MAPをスクロールして計測場所を調整して下さい",
  "都道府県までしか住所が判別できませんでした。代表地点を表示します。",
  "市区町村までしか住所が判別できませんでした。代表地点を表示します。",
  "",
]

export const DEFAULT_NOTIFICATIONS_MAP_LOCATION = {
  lat: 35.6818718,
  lng: 139.7658469,
}

export default {
  ENV,
  API_URL,
  GOOGLE_API_KEY,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_APP_CLIENT_ID,
  SERVICE_LAUNCHED_AT,
  COOKIE_EXPIREDAYS,
  COOKIE_KEYS,
  DEFAULT_NOTIFICATIONS_MAP_LOCATION,
}
