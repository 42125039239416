import React from 'react'
import moment from 'moment'

import {
  Button,
  withStyles,
  WithStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

import { TrendType } from 'utils/trendType'
import Months from './Months'
import Years from './Years'
import Weeks from './Weeks'
import Days from './Days'
import styles from './styles'

type UsersGraphsProps = WithStyles<typeof styles> & {
  open: boolean
  type: TrendType
  activeDate: moment.Moment
  onChange: (from: moment.Moment, to: moment.Moment) => void
  onClose: () => void
}

class UsersGraphs extends React.Component<UsersGraphsProps> {
  render() {
    const { open, type, activeDate, onChange, onClose } = this.props

    const component = (() => {
      switch (type) {
        case TrendType.Day:
          return <Days activeDate={activeDate} onChange={onChange} />
        case TrendType.Week:
          return <Weeks activeDate={activeDate} onChange={onChange} />
        case TrendType.Month:
          return <Months activeDate={activeDate} onChange={onChange} />
        case TrendType.Year:
          return <Years activeDate={activeDate} onChange={onChange} />
      }
    })()

    return (
      <Dialog maxWidth="lg" open={open} onClose={onClose}>
        <DialogTitle>
          <Typography align="center">表示する範囲を選択</Typography>
        </DialogTitle>
        <DialogContent>{component}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(UsersGraphs)
