import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import { actions as observationsActions, ObservationsState } from 'redux/modules/observations'
import Observation from 'redux/models/observation'
import Screen from './Observations'

export interface ObservationsActions {
  fetchObservations: () => Action<void>
  requestObservation: (observation: Observation) => Action<Observation>
}

export interface ObservationsStoreStates {
  auth: AuthState
  observations: ObservationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): ObservationsActions {
  return {
    fetchObservations: () => dispatch(observationsActions.fetchObservations()),
    requestObservation: (observation) =>
      dispatch(observationsActions.requestObservation(observation)),
  }
}

function mapStateToProps(appState: AppState): ObservationsStoreStates {
  return {
    auth: appState.auth,
    observations: appState.observations,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
