import Cookies from 'js-cookie';
import { COOKIE_KEYS } from 'utils/Constants'

export function getItem(key: COOKIE_KEYS) {
  const value = Cookies.get(key);
  if (value) {
    return JSON.parse(value);
  }
  return "";
}

export function removeItem(key: COOKIE_KEYS) {
  Cookies.remove(key);
}

export function setItem(key: COOKIE_KEYS, value: any, expiredays: number) {
  Cookies.set(key, JSON.stringify(value), { expires: expiredays, path: '/',  secure: true});
}

export default {
  getItem,
  removeItem,
  setItem,
}