import { UserData } from 'utils/apiClient'
import moment from 'moment'
import { formatDateTime } from 'utils/moment'
import UserGroup from './userGroup'

export const UserType = {
  User: 0,
  Admin: 1,
} as const
export type UserType = typeof UserType[keyof typeof UserType]

export const userTypeTexts = {
  [UserType.User]: '一般ユーザー',
  [UserType.Admin]: '管理者',
}

export default class User {
  id: number
  code: string
  name: string
  type: UserType
  userGroup: UserGroup
  createdAt: moment.Moment

  get isAdmin(): boolean {
    return this.type === UserType.Admin
  }

  get userTypeText(): string {
    return userTypeTexts[this.type]
  }

  get createdDate(): string {
    return formatDateTime(this.createdAt)
  }

  constructor(
    id: number,
    code: string,
    name: string,
    type: UserType,
    userGroup: UserGroup,
    createdAt: moment.Moment,
  ) {
    this.id = id
    this.code = code
    this.name = name
    this.type = type
    this.userGroup = userGroup
    this.createdAt = createdAt
  }

  static load(data: UserData): User {
    return new User(
      data.id,
      data.userCode,
      data.name,
      data.type,
      UserGroup.load(data.userGroup),
      moment(data.createdAt),
    )
  }

  static loadAll(data: UserData[]): User[] {
    return data.map((item) => this.load(item))
  }
}
