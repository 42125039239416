import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import User from 'redux/models/user'
import UserStatus from 'redux/models/userStatus'
import Observation from 'redux/models/observation'

// Actions
export const FETCH_OBSERVATIONS = 'hamon/observations/FETCH_OBSERVATIONS'
export const FETCH_OBSERVATIONS_COMPETION = 'hamon/observations/FETCH_OBSERVATIONS_COMPETION'
export const FETCH_OBSERVATION_REQUESTS = 'hamon/observations/FETCH_OBSERVATION_REQUESTS'
export const FETCH_OBSERVATION_REQUESTS_COMPETION =
  'hamon/observations/FETCH_OBSERVATION_REQUESTS_COMPETION'
export const FETCH_STATUS_LIST = 'hamon/observations/FETCH_STATUS_LIST'
export const FETCH_STATUS_LIST_COMPLETION = 'hamon/observations/FETCH_STATUS_LIST_COMPLETION'
export const REQUEST_OBSERVATION = 'hamon/observations/REQUEST_OBSERVATION'
export const REQUEST_OBSERVATION_COMPLETION = 'hamon/observations/REQUEST_OBSERVATION_COMPLETION'
export const SEND_OBSERVATION_REQUEST_ANSWER = 'hamon/observations/SEND_OBSERVATION_REQUEST_ANSWER'
export const SEND_OBSERVATION_REQUEST_ANSWER_COMPLETION =
  'hamon/observations/SEND_OBSERVATION_REQUEST_ANSWER_COMPLETION'
export const RESET_OBSERVATIONS = 'hamon/observations/RESET_OBSERVATIONS'
export const RESET_OBSERVATIONS_COMPLETION = 'hamon/observations/RESET_OBSERVATIONS_COMPLETION'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface AnswerPayload {
  observation: Observation
  isApproved: boolean
}

export const actions = {
  fetchObservations: actionCreator(FETCH_OBSERVATIONS),
  completedToFetchObservations: actionCreator<Observation[] | null>(FETCH_OBSERVATIONS_COMPETION),
  fetchObservationRequests: actionCreator(FETCH_OBSERVATION_REQUESTS),
  completedToFetchObservationRequests: actionCreator<Observation[] | null>(
    FETCH_OBSERVATION_REQUESTS_COMPETION,
  ),
  fetchStatusList: actionCreator(FETCH_STATUS_LIST),
  completedToFetchStatusList: actionCreator<UserStatus[] | null>(FETCH_STATUS_LIST_COMPLETION),
  requestObservation: actionCreator<Observation>(REQUEST_OBSERVATION),
  completedToRequestObservation: actionCreator<string | null>(REQUEST_OBSERVATION_COMPLETION),
  sendObservationRequestAnswer: actionCreator<AnswerPayload>(SEND_OBSERVATION_REQUEST_ANSWER),
  completedToSendObservationRequestAnswer: actionCreator<string | null>(
    SEND_OBSERVATION_REQUEST_ANSWER_COMPLETION,
  ),
  resetObservations: actionCreator<User>(RESET_OBSERVATIONS),
  completedToResetObservations: actionCreator<string | null>(RESET_OBSERVATIONS_COMPLETION),
}

// Reducer
export interface ObservationsState {
  observations: {
    fetching: boolean
    fetched: boolean
    items: Observation[]
  }
  observationRequests: {
    fetching: boolean
    fetched: boolean
    items: Observation[]
  }
  statuses: {
    fetching: boolean
    items: UserStatus[]
  }
  request: {
    observation: Observation | null
    error: string | null
  }
  answer: {
    observation: Observation | null
    error: string | null
  }
  reset: {
    user: User | null
    error: string | null
  }
}

const initialState: () => ObservationsState = () => ({
  observations: {
    fetching: false,
    fetched: false,
    items: [],
  },
  observationRequests: {
    fetching: false,
    fetched: false,
    items: [],
  },
  statuses: {
    fetching: false,
    items: [],
  },
  request: {
    observation: null,
    error: null,
  },
  answer: {
    observation: null,
    error: null,
  },
  reset: {
    user: null,
    error: null,
  },
})

export default reducerWithInitialState(initialState())
  .case(actions.fetchObservations, (state) => {
    const observations = state.observations
    return {
      ...state,
      observations: {
        ...observations,
        fetching: true,
      },
    }
  })
  .case(actions.completedToFetchObservations, (state, items) => {
    const observations = state.observations
    return {
      ...state,
      observations: {
        ...observations,
        fetching: false,
        fetched: true,
        items: items === null ? observations.items : items,
      },
    }
  })
  .case(actions.fetchObservationRequests, (state) => {
    const observationRequests = state.observationRequests
    return {
      ...state,
      observationRequests: {
        ...observationRequests,
        fetching: true,
      },
    }
  })
  .case(actions.completedToFetchObservationRequests, (state, items) => {
    const observationRequests = state.observationRequests
    return {
      ...state,
      observationRequests: {
        ...observationRequests,
        fetching: false,
        fetched: true,
        items: items === null ? observationRequests.items : items,
      },
    }
  })
  .case(actions.fetchStatusList, (state) => {
    const statuses = state.statuses
    return {
      ...state,
      statuses: {
        ...statuses,
        fetching: true,
      },
    }
  })
  .case(actions.completedToFetchStatusList, (state, items) => {
    const statuses = state.statuses
    return {
      ...state,
      statuses: {
        ...statuses,
        fetching: false,
        items: items === null ? statuses.items : items,
      },
    }
  })
  .case(actions.requestObservation, (state, observation) => ({
    ...state,
    request: {
      observation,
      error: null,
    },
  }))
  .case(actions.completedToRequestObservation, (state, error) => ({
    ...state,
    request: {
      observation: null,
      error,
    },
  }))
  .case(actions.sendObservationRequestAnswer, (state, payload) => ({
    ...state,
    answer: {
      observation: payload.observation,
      error: null,
    },
  }))
  .case(actions.completedToSendObservationRequestAnswer, (state, error) => ({
    ...state,
    answer: {
      observation: null,
      error,
    },
  }))
  .case(actions.resetObservations, (state, user) => ({
    ...state,
    reset: {
      user,
      error: null,
    },
  }))
  .case(actions.completedToResetObservations, (state, error) => ({
    ...state,
    reset: {
      user: null,
      error,
    },
  }))
