import axios from 'axios'
import Cognito from 'utils/Cognito'
import { Moment } from 'moment'

import { API_URL } from 'utils/Constants'
import { UserType } from 'redux/models/user'
import { AnalysisData, Condition } from 'redux/models/userStatus'
import { ObservationStatus } from 'redux/models/observation'
import { NotificationType } from 'redux/models/notification'
import { AlertActionType } from 'redux/models//alertAction'
import { UserGroupConfig } from 'redux/models/userGroup'

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json;charset=UTF-8',
  },
  timeout: 1000 * 300, // ms
})

type Data = { [key: string]: unknown }

const authHeader = (token: string) => ({
  Authorization: `Bearer ${token}`,
})

// Requests ------------

const get = async <D>(path: string, params: Data = {}): Promise<D> => {
  const token: string = await Cognito.getAccessToken()

  const { data } = await api.get(path, {
    params,
    headers: authHeader(token),
  })

  return data as D
}

const post = async <D>(path: string, body: unknown): Promise<D> => {
  const token: string = await Cognito.getAccessToken()

  const { data } = await api.post(path, body, { headers: authHeader(token) })
  return data as D
}

const put = async <D>(path: string, body: unknown): Promise<D> => {
  const token: string = await Cognito.getAccessToken()

  const { data } = await api.put(path, body, { headers: authHeader(token) })
  return data as D
}

const deleteRequest = async <D>(path: string, body: unknown = {}): Promise<D> => {
  const token: string = await Cognito.getAccessToken()

  const { data } = await api.delete(path, { data: body, headers: authHeader(token) })
  return data as D
}

// Response data types ------

export type AlertActionData = {
  id: number
  type: AlertActionType
  message: string
  createdAt: string
  user: UserData
  actedUser: UserData
}

export type HeartRateData = {
  id: number
  timestamp: number
  value: number
  createdAt: Date
  user: UserData
}

export type HeartRateTrendData = {
  id: number
  timestamp: number
  minValue: number
  maxValue: number
  averageValue: number
  count: number
  createdAt: Date
  user: UserData
}

export type HeatRiskTrendData = {
  id: number
  timestamp: number
  minValue: number
  maxValue: number
  averageValue: number
  count: number
  createdAt: Date
  user: UserData
}

export type LocationData = {
  id: number
  latitude: number
  longitude: number
  altitude?: number
  horizontalAccuracy?: number
  verticalAccuracy?: number
}

export type NotificationData = {
  id: number
  message: string
  timestamp: number
  user: UserData
  location?: LocationData
  notificationType: {
    id: number
    name: NotificationType
  }
}

export type ObservationData = {
  id: number
  userCode: string
  name: string
  observationStatus: ObservationStatus
  createdAt: string
}

export type StressData = {
  id: number
  timestamp: number
  value: number
  condition: Condition
  createdAt: string
  user: UserData
}

export type StressTrendData = {
  id: number
  timestamp: number
  minValue: number
  maxValue: number
  averageValue: number
  normalCount: number
  warningCount: number
  dangerCount: number
  createdAt: Date
  user: UserData
}

export type UserData = {
  id: number
  userCode: string
  name: string
  type: UserType
  createdAt: string
  userGroup: UserGroupData
}

export type UserGroupData = {
  id: number
  code: string
  name: string
  availableFrom: string | null
  availableTo: string | null
  configs?: UserGroupConfig
  createdAt: string
}

export type UserStatusData = {
  userId: number
  userName: string
  isConnecting: boolean
  heartRate: number | null
  alert: {
    count: number
    coreBodyTemperature: AnalysisData | null
    drowsiness: AnalysisData | null
    hyperthermia: AnalysisData | null
    stress: AnalysisData | null
  }
  analysis: {
    coreBodyTemperature: AnalysisData | null
    drowsiness: AnalysisData | null
    hyperthermia: AnalysisData | null
    stress: AnalysisData | null
  }
  isTransmitter: boolean
}

type AlertActionsResponseData = { actions: AlertActionData[] }
type HeartRatesResponseData = { items: HeartRateData[] }
type HeartRateTrendsResponseData = { items: HeartRateTrendData[] }
type HeatRiskTrendsResponseData = { items: HeatRiskTrendData[] }
export type NotificationsResponseData = { notifications: NotificationData[]; totalCount: number }
type ObservationsResponseData = { users: ObservationData[] }
type StresssResponseData = { items: StressData[] }
type StressTrendsResponseData = { items: StressTrendData[] }
type UserGroupResponseData = { userGroup: UserGroupData }
type UserGroupsResponseData = { userGroups: UserGroupData[] }
type UserResponseData = { user: UserData }
type UserStatusesResponseData = { data: UserStatusData[] }
type UsersResponseData = { users: UserData[] }

// APIs ------------

const addUserGroup = async (data: {
  name: string
  code: string
  availableFrom: Moment | null
  availableTo: Moment | null
}) => {
  const res = await post<UserGroupResponseData>('/v1/userGroups', data)
  return res.userGroup
}

const addUsers = async (data: {
  users: {
    userGroupId: number
    name: string
    code: string
    password: string
  }[]
}) => {
  const res = await post<UsersResponseData>('/v1/users', data)
  return res.users
}

const changePassword = async (currentPassword: string, newPassword: string) => {
  return await Cognito.changePassword(currentPassword, newPassword)
}

const deleteUser = async (id: number) => {
  return await deleteRequest(`/v1/users/${id}`)
}

const deleteUserGroup = async (id: number, physics: boolean) => {
  return physics
    ? await deleteRequest(`/v1/userGroups/${id}/physics`)
    : await deleteRequest(`/v1/userGroups/${id}`)
}

const getAlertActions = async (userId: number) => {
  const res = await get<AlertActionsResponseData>(`/v1/alerts/actions?userId=${userId}`)
  return res.actions
}

const getHeartRateTrends = async (userId: number, from: number, to: number) => {
  const res = await get<HeartRateTrendsResponseData>(
    `/v1/heartRates/trends?userId=${userId}&from=${from}&to=${to}`,
  )
  return res.items
}

const getHeatRiskTrends = async (userId: number, from: number, to: number) => {
  const res = await get<HeatRiskTrendsResponseData>(
    `/v1/heatRisks/trends?userId=${userId}&from=${from}&to=${to}`,
  )
  return res.items
}

const getNotifications = async (
  page: number,
  limit: number,
  from?: Moment,
  to?: Moment,
  userIds?: number[],
  types?: NotificationType[],
  needsLocation?: boolean,
) => {
  let path = `/v1/notifications?page=${page}&limit=${limit}`
  if (from) path += `&timestampFrom=${from.unix()}`
  if (to) path += `&timestampTo=${to.unix()}`
  if (userIds) {
    const query = userIds.join('&userIds[]=')
    path += `&userIds[]=${query}`
  }
  if (types) {
    const query = types.join('&types[]=')
    path += `&types[]=${query}`
  }
  if (needsLocation) path += '&needsLocation=1'

  return await get<NotificationsResponseData>(path)
}

const getObservationRequests = async () => {
  const res = await get<ObservationsResponseData>('/v1/observations/requests')
  return res.users
}

const getObservations = async () => {
  const res = await get<ObservationsResponseData>('/v1/observations/')
  return res.users
}

const getStresss = async (userId: number, from: number, to: number) => {
  const res = await get<StresssResponseData>(`/v1/stresses?userId=${userId}&from=${from}&to=${to}`)
  return res.items
}

const getHBStresss = async (userId: number, from: number, to: number) => {
  const res = await get<StresssResponseData>(`/v1/hbStresses?userId=${userId}&from=${from}&to=${to}`)
  return res.items
}

const getStressTrends = async (userId: number, from: number, to: number) => {
  const res = await get<StressTrendsResponseData>(
    `/v1/stresses/trends?userId=${userId}&from=${from}&to=${to}`,
  )
  return res.items
}

const getUserStatuses = async (userIds: number[]) => {
  const query = userIds.join('&userIds[]=')
  const res = await get<UserStatusesResponseData>(`/v1/observations/statuses?userIds[]=${query}`)
  return res.data
}

const getUser = async (id: number | 'myself') => {
  const data = await get<UserResponseData>(`/v1/users/${id}`)
  return data.user
}

const getUserGroups = async () => {
  const data = await get<UserGroupsResponseData>('/v1/userGroups')
  return data.userGroups
}

const getUsers = async () => {
  const data = await get<UsersResponseData>('/v1/users')
  return data.users
}

const logIn = async (userName: string, password: string) => {
  await Cognito.logIn(userName, password)
  return await getUser('myself')
}

const logOut = async () => {
  Cognito.logOut()
}

const requestObservation = async (userId: number) => {
  return await post('/v1/observations', { userId })
}

const resetObservations = async (userId: number) => {
  return await deleteRequest('/v1/observations/reset', { userId })
}

const sendAlertAction = async (data: {
  userId: number
  type: AlertActionType
  message: string
}) => {
  return await post('/v1/alerts/actions', data)
}

const sendObservationRequestAnswer = async (userId: number, isApproved: boolean) => {
  return await put('/v1/observations/requests', { userId, isApproved })
}

const updateUser = async (data: {
  id: number
  userGroupId: number
  name: string
  code: string
  password?: string
}) => {
  const { id, ...body } = data
  const res = await put<UserResponseData>(`/v1/users/${id}`, body)
  return res.user
}

const updateUserGroup = async (data: {
  id: number
  name: string
  code: string
  availableFrom: Moment | null
  availableTo: Moment | null
}) => {
  const { id, ...body } = data
  const res = await put<UserGroupResponseData>(`/v1/userGroups/${id}`, body)
  return res.userGroup
}

const updateUserGroupConfig = async (id: number, configs: UserGroupConfig) => {
  await put(`/v1/userGroups/${id}/config`, configs)
}

export default {
  addUserGroup,
  addUsers,
  changePassword,
  deleteUser,
  deleteUserGroup,
  getAlertActions,
  getHeartRateTrends,
  getHeatRiskTrends,
  getNotifications,
  getObservationRequests,
  getObservations,
  getStresss,
  getHBStresss,
  getStressTrends,
  getUser,
  getUserGroups,
  getUsers,
  getUserStatuses,
  logIn,
  logOut,
  requestObservation,
  resetObservations,
  sendAlertAction,
  sendObservationRequestAnswer,
  updateUser,
  updateUserGroup,
  updateUserGroupConfig,
}
