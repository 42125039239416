import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
    },
    header: {
      height: 70,
    },
    content: {
      overflow: 'scroll',
    },
    mainContainer: {
      minHeight: 'calc(100% - 30px)',
      padding: theme.spacing(2),
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
    },
    footer: {
      height: 30,
      padding: theme.spacing(1),
    },
  })
