import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState } from 'redux/modules/auth'
import Screen from './MainContainer'

export interface ContainerActions {
  logOut: () => Action<void>
}

export interface ContainerStoreStates {
  auth: AuthState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): ContainerActions {
  return {
    logOut: () => dispatch(authActions.logOut()),
  }
}

function mapStateToProps(appState: AppState): ContainerStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
