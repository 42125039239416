import React from 'react'
import { withStyles, WithStyles, Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import { DrawerProps } from '@material-ui/core/Drawer'

import items from 'utils/navigationItems'
import { Icons } from 'utils/Icon'
import User from 'redux/models/user'

import { navigationStyles } from './styles'
import NavigatorItem from './NavigatorItem'

interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof navigationStyles> {
  user: User
  numberOfObservationRequests: number
  onMenuClick?: () => void
}

class Navigator extends React.Component<NavigatorProps> {
  render() {
    const { classes, user, numberOfObservationRequests, onMenuClick, ...other } = this.props
    const userMenus = items.filter((item) => !item.onlyAdmin || user.isAdmin)

    return (
      <Drawer variant="permanent" {...other}>
        <List disablePadding>
          <ListItem className={classes.logoContainer}>
            <img className={classes.logo} src={Icons.Logo} alt="logo" />
          </ListItem>

          {userMenus.map(({ title, items: children }) => (
            <React.Fragment key={title}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText classes={{ primary: classes.categoryHeaderPrimary }}>
                  {title}
                </ListItemText>
              </ListItem>
              {children
                .filter(({ showMenu }) => showMenu)
                .map(({ title: childTitle, icon, path, pattern }) => {
                  const badge =
                    path === '/observations/requests' ? numberOfObservationRequests : null
                  const to = path.replace(/:userId/, `${user.id}`)
                  return (
                    <NavigatorItem
                      key={childTitle}
                      title={childTitle}
                      icon={icon}
                      to={to}
                      pattern={pattern}
                      badge={badge}
                      onClick={() => onMenuClick && onMenuClick()}
                    />
                  )
                })}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    )
  }
}

export default withStyles(navigationStyles)(Navigator)
