import React from 'react'
import { RouteComponentProps } from 'react-router'

import { Button, withStyles, WithStyles, Typography, Grid } from '@material-ui/core'
import { MainContainer, Table } from 'components'

import { NotificationsActions, NotificationsStoreStates } from '.'
import styles from './styles'

type NotificationsProps = WithStyles<typeof styles> &
  RouteComponentProps &
  NotificationsActions &
  NotificationsStoreStates

class Notifications extends React.Component<NotificationsProps> {
  constructor(props: NotificationsProps) {
    super(props)
    this.fetchNotifications()
  }

  componentDidUpdate(prevProps: NotificationsProps) {
    const prevSearch = prevProps.location.search
    const currentSearch = this.props.location.search

    if (prevSearch !== currentSearch) this.fetchNotifications()
  }

  fetchNotifications() {
    const queries = new URLSearchParams(this.props.location.search)
    const page = parseInt(queries.get('page') || '1') || 1
    this.props.fetchNotifications(page)
  }

  headerFor(index: number) {
    switch (index) {
      case 0:
        return '名前'
      case 1:
        return 'アラート'
      case 2:
        return '発生日時'
      default:
        return ''
    }
  }

  render() {
    const { history, notifications, fetchNotifications } = this.props
    const { items, fetched, fetching, page, limit, count } = notifications.list

    const rows = items.map((item) => [item.user.name, item.message, item.notifiedDate])

    return (
      <MainContainer
        headerItem={
          <Button variant="contained" onClick={() => fetchNotifications(page)}>
            {fetching ? '更新中' : '更新する'}
          </Button>
        }>
        <Table
          fetched={fetched}
          headers={['名前', 'アラート', '発生日時']}
          rows={rows}
          noContentItem={
            <Grid container direction="column">
              <Grid>
                <Typography variant="h3" align="center">
                  表示できる通知がありません。
                </Typography>
              </Grid>
              <Typography variant="h6" align="center">
                他のユーザーの通知を見る場合は、バイタル閲覧申請から申請してください。
              </Typography>
            </Grid>
          }
          pagination={{
            count,
            page,
            rowsPerPage: limit,
          }}
          onChangePage={(page) => history.push(`/notifications?page=${page}`)}
        />
      </MainContainer>
    )
  }
}

export default withStyles(styles)(Notifications)
