import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import UserGroup, { UserGroupConfig } from 'redux/models/userGroup'
import { Moment } from 'moment'

// Actions
export const FETCH_LIST = 'hamon/userGroups/FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'hamon/userGroups/FETCH_LIST_SUCCESS'
export const FETCH_LIST_FAILURE = 'hamon/userGroups/FETCH_LIST_FAILURE'
export const ADD_USER_GROUP = 'hamon/userGroups/ADD_USER_GROUP'
export const ADD_USER_GROUP_SUCCESS = 'hamon/userGroups/ADD_USER_GROUP_SUCCESS'
export const ADD_USER_GROUP_FAILURE = 'hamon/userGroups/ADD_USER_GROUP_FAILURE'
export const UPDATE_USER_GROUP = 'hamon/userGroups/UPDATE_USER_GROUP'
export const UPDATE_USER_GROUP_SUCCESS = 'hamon/userGroups/UPDATE_USER_GROUP_SUCCESS'
export const UPDATE_USER_GROUP_FAILURE = 'hamon/userGroups/UPDATE_USER_GROUP_FAILURE'
export const DELETE_USER_GROUP = 'hamon/userGroups/DELETE_USER_GROUP'
export const DELETE_USER_GROUP_SUCCESS = 'hamon/userGroups/DELETE_USER_GROUP_SUCCESS'
export const DELETE_USER_GROUP_FAILURE = 'hamon/userGroups/DELETE_USER_GROUP_FAILURE'
export const UPDATE_USER_GROUP_CONFIG = 'hamon/userGroups/UPDATE_USER_GROUP_CONFIG'
export const UPDATE_USER_GROUP_CONFIG_SUCCESS = 'hamon/userGroups/UPDATE_USER_GROUP_CONFIG_SUCCESS'
export const UPDATE_USER_GROUP_CONFIG_FAILURE = 'hamon/userGroups/UPDATE_USER_GROUP_CONFIG_FAILURE'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface AddUserGroupPayload {
  name: string
  code: string
  availableFrom: Moment | null
  availableTo: Moment | null
}

export interface UpdateUserGroupPayload {
  group: UserGroup
  name: string
  code: string
  availableFrom: Moment | null
  availableTo: Moment | null
}

export interface DeleteUserGroupPayload {
  group: UserGroup
  physics: boolean
}

export interface UpdateUserGroupConfigPayload {
  group: UserGroup
  configs: UserGroupConfig
}

export const actions = {
  fetchList: actionCreator(FETCH_LIST),
  succeededToFetchList: actionCreator<UserGroup[]>(FETCH_LIST_SUCCESS),
  failedToFetchList: actionCreator<string>(FETCH_LIST_FAILURE),
  addUserGroup: actionCreator<AddUserGroupPayload>(ADD_USER_GROUP),
  succeededToAddUserGroup: actionCreator<UserGroup>(ADD_USER_GROUP_SUCCESS),
  failedToAddUserGroup: actionCreator<string>(ADD_USER_GROUP_FAILURE),
  updateUserGroup: actionCreator<UpdateUserGroupPayload>(UPDATE_USER_GROUP),
  succeededToUpdateUserGroup: actionCreator<UserGroup>(UPDATE_USER_GROUP_SUCCESS),
  failedToUpdateUserGroup: actionCreator<string>(UPDATE_USER_GROUP_FAILURE),
  deleteUserGroup: actionCreator<DeleteUserGroupPayload>(DELETE_USER_GROUP),
  succeededToDeleteUserGroup: actionCreator<UserGroup>(DELETE_USER_GROUP_SUCCESS),
  failedToDeleteUserGroup: actionCreator<string>(DELETE_USER_GROUP_FAILURE),
  updateUserGroupConfig: actionCreator<UpdateUserGroupConfigPayload>(UPDATE_USER_GROUP_CONFIG),
  succeededToUpdateUserGroupConfig: actionCreator<UserGroup>(UPDATE_USER_GROUP_CONFIG_SUCCESS),
  failedToUpdateUserGroupConfig: actionCreator<string>(UPDATE_USER_GROUP_CONFIG_FAILURE),
}

// Reducer
export interface UserGroupsState {
  items: UserGroup[]
  fetchingList: boolean
  fetchListError: string | null
  add: {
    adding: boolean
    error: string | null
  }
  update: {
    updating: boolean
    error: string | null
  }
  delete: {
    deleting: boolean
    error: string | null
  }
  updateConfig: {
    updating: boolean
    error: string | null
  }
}

const initialState: () => UserGroupsState = () => ({
  items: [],
  fetchingList: false,
  fetchListError: null,
  add: {
    adding: false,
    error: null,
  },
  update: {
    updating: false,
    error: null,
  },
  delete: {
    deleting: false,
    error: null,
  },
  updateConfig: {
    updating: false,
    error: null,
  },
})

export default reducerWithInitialState(initialState())
  .case(actions.fetchList, (state) => ({
    ...state,
    fetchingList: true,
    fetchListError: null,
  }))
  .case(actions.succeededToFetchList, (state, items) => ({
    ...state,
    items,
    fetchingList: false,
    fetchListError: null,
  }))
  .case(actions.failedToFetchList, (state, error) => ({
    ...state,
    items: [],
    fetchingList: false,
    fetchListError: error,
  }))
  .case(actions.addUserGroup, (state) => {
    const add = state.add
    return {
      ...state,
      add: {
        ...add,
        adding: true,
        error: null,
      },
    }
  })
  .case(actions.succeededToAddUserGroup, (state, item) => {
    const items = state.items.concat(item)
    const add = state.add
    return {
      ...state,
      items,
      add: {
        ...add,
        adding: false,
        error: null,
      },
    }
  })
  .case(actions.failedToAddUserGroup, (state, error) => {
    const add = state.add
    return {
      ...state,
      add: {
        ...add,
        adding: false,
        error,
      },
    }
  })
  .case(actions.updateUserGroup, (state) => {
    const update = state.update
    return {
      ...state,
      update: {
        ...update,
        updating: true,
        error: null,
      },
    }
  })
  .case(actions.succeededToUpdateUserGroup, (state, item) => {
    const update = state.update
    const items = [...state.items]
    const index = items.findIndex((i) => i.id === item.id)
    items[index] = item

    return {
      ...state,
      items,
      update: {
        ...update,
        updating: false,
        error: null,
      },
    }
  })
  .case(actions.failedToUpdateUserGroup, (state, error) => {
    const update = state.update
    return {
      ...state,
      update: {
        ...update,
        updating: false,
        error,
      },
    }
  })
  .case(actions.deleteUserGroup, (state) => {
    const current = state.delete
    return {
      ...state,
      delete: {
        ...current,
        deleting: true,
        error: null,
      },
    }
  })
  .case(actions.succeededToDeleteUserGroup, (state, item) => {
    const current = state.delete
    const items = state.items.filter((i) => i.id !== item.id)

    return {
      ...state,
      items,
      delete: {
        ...current,
        deleting: false,
        error: null,
      },
    }
  })
  .case(actions.failedToDeleteUserGroup, (state, error) => {
    const current = state.delete
    return {
      ...state,
      delete: {
        ...current,
        deleting: false,
        error,
      },
    }
  })
  .case(actions.updateUserGroupConfig, (state) => {
    const update = state.updateConfig
    return {
      ...state,
      updateConfig: {
        ...update,
        updating: true,
        error: null,
      },
    }
  })
  .case(actions.succeededToUpdateUserGroupConfig, (state, item) => {
    const update = state.updateConfig
    const items = [...state.items]
    const index = items.findIndex((i) => i.id === item.id)
    items[index] = item
    return {
      ...state,
      items,
      updateConfig: {
        ...update,
        updating: false,
        error: null,
      },
    }
  })
  .case(actions.failedToUpdateUserGroupConfig, (state, error) => {
    const update = state.updateConfig
    return {
      ...state,
      updateConfig: {
        ...update,
        updating: false,
        error,
      },
    }
  })
