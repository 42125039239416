import { createStyles, Theme } from '@material-ui/core'
import colors from 'utils/Color'

export default (theme: Theme) =>
  createStyles({
    dateButton: {
      height: 40,
      marginRight: theme.spacing(1),
      backgroundColor: colors.White,
      fontSize: 15,
      fontWeight: 'bold',
      boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
    },
    dateButtonActive: {
      color: colors.White,
      backgroundColor: colors.Green,
      boxShadow: 'none',
    },
    calenderButton: {
      height: 40,
      marginRight: theme.spacing(1),
      backgroundColor: colors.White,
      fontSize: 15,
      boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
    },
    calenderButtonIcon: {
      marginRight: theme.spacing(1),
    },
    graphContainer: {
      marginTop: theme.spacing(2),
    },
  })
