import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import User from 'redux/models/user'
import { UsersActions, UsersStoreStates } from '.'

import { Button, Grid, Typography, TableCell, WithStyles, withStyles } from '@material-ui/core'
import { LayersClear } from '@material-ui/icons'
import {
  MainContainer,
  BlueButton,
  SectionHeader,
  FormContainer,
  Form,
  SelectForm,
  Table,
} from 'components'
import { AccessoryCell } from 'components/Table'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles> & UsersActions & UsersStoreStates

interface UsersStates {
  userGroupId: number
  name: string
  code: string
}

class Users extends React.Component<OwnProps, UsersStates> {
  constructor(props: OwnProps) {
    super(props)

    this.state = {
      userGroupId: 0,
      name: '',
      code: '',
    }

    props.fetchUserGroupList()
    props.fetchUserList()
  }

  componentDidUpdate(prevProps: OwnProps) {
    const { observations } = this.props
    const current = observations.reset
    const previous = prevProps.observations.reset

    if (previous.user && !current.user) {
      // 監視情報のリセットが終了したらアラート
      alert(current.error || `${previous.user.name}の閲覧情報をリセットしました`)
    }
  }

  checkDelete(user: User) {
    if (window.confirm(`${user.name}を削除してよろしいですか？`)) this.props.deleteUser(user)
  }

  checkObservationReset(user: User) {
    if (window.confirm(`${user.name}の閲覧情報をリセットしてよろしいですか？`))
      this.props.resetObservations(user)
  }

  renderObservationResetCell(item: User) {
    const { classes } = this.props

    return (
      <TableCell key={`reset-${item.id}`} className={classes.resetCell}>
        <Button className={classes.cellButton} onClick={() => this.checkObservationReset(item)}>
          <Grid container direction="column">
            <Grid>
              <LayersClear fontSize="large" />
            </Grid>
            <Grid>
              <Typography className={classes.cellButtonText}>閲覧情報</Typography>
              <Typography className={classes.cellButtonText}>リセット</Typography>
            </Grid>
          </Grid>
        </Button>
      </TableCell>
    )
  }

  render() {
    const { userGroupId, name, code } = this.state
    const { history, users, userGroups, fetchUserList } = this.props
    const { items, fetchingList, fetchListError } = users

    const options = [{ label: '企業名', value: 0 }].concat(
      userGroups.items.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    )

    const filteredItems = items.filter(
      (item) =>
        [0, item.userGroup.id].includes(userGroupId) &&
        item.name.includes(name) &&
        item.code.includes(code),
    )
    const rows = filteredItems.map((item) => [
      item.userGroup.name,
      item.name,
      item.code,
      item.createdDate,
    ])
    const accessories: AccessoryCell[][] = filteredItems.map((item) => [
      'edit',
      this.renderObservationResetCell(item),
      'delete',
    ])

    return (
      <MainContainer
        headerItem={
          <Button variant="contained" onClick={() => fetchUserList()}>
            {fetchingList ? '更新中' : '更新する'}
          </Button>
        }>
        <Grid container direction="row" justify="flex-end">
          <BlueButton
            variant="contained"
            title="新規ユーザー登録"
            onClick={() => history.push('/admin/users/new')}
          />
        </Grid>

        <SectionHeader>絞り込み</SectionHeader>

        <FormContainer>
          <SelectForm
            value={`${userGroupId}`}
            label="所属企業"
            options={options}
            onChange={(value) => this.setState({ userGroupId: parseInt(value) })}
          />
          <Form value={name} label="ユーザー名" onChange={(name) => this.setState({ name })} />
          <Form value={code} label="ユーザーID" onChange={(code) => this.setState({ code })} />
        </FormContainer>

        <SectionHeader>検索結果</SectionHeader>
        {fetchListError && <Typography>{fetchListError}</Typography>}

        <Table
          fetched
          headers={['所属企業', 'ユーザ名', 'ユーザID', '登録日時', '', '', '']}
          rows={rows}
          accessories={accessories}
          onEdit={(index) => history.push(`/admin/users/${filteredItems[index].id}/edit`)}
          onDelete={(index) => this.checkDelete(filteredItems[index])}
        />
      </MainContainer>
    )
  }
}

export default withStyles(styles)(Users)
