import React from 'react'
import moment from 'moment'
import { RouteComponentProps } from 'react-router'
import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import {
  Button,
  withStyles,
  WithStyles,
  Typography,
  Grid,
  Paper,
  IconButton,
} from '@material-ui/core'
import { Room, Close } from '@material-ui/icons'

import { GOOGLE_API_KEY, DEFAULT_NOTIFICATIONS_MAP_LOCATION, NOTIFICATIONS_MAP_ERROR_MSG } from 'utils/Constants'
import {
  MainContainer,
  SectionHeader,
  FormContainer,
  PeriodForm,
  MultipleSelectForm,
  CheckBoxForm,
  CheckBoxSingleForm,
  SelectForm,
  GreenButton,
  SearchForm,
  NormalTypography,
  ErrorTypography,
} from 'components'
import { NotificationsMapActions, NotificationsMapStoreStates } from '.'
import Pin from './Pin'
import styles, { pinStyle } from './styles'
import { notificationTypeTexts, NotificationType } from 'redux/models/notification'
import { ObservationStatus } from 'redux/models/observation'
import cookie from 'utils/cookie/notificationsMap'

type NotificationsMapProps = WithStyles<typeof styles> &
  RouteComponentProps &
  NotificationsMapActions &
  NotificationsMapStoreStates

interface NotificationsMapStates {
  currentLocation: {
    lat: number
    lng: number
  }
  filter: {
    fromDate: moment.Moment
    toDate: moment.Moment
    userIds: number[]
    types: NotificationType[]
    limit: number
    locationName: string
  }
  selectedNotificationId: number | null
  isUserFilterInitialized: boolean
  isSaveFilter: boolean,
  map: {
    zoom: number
  },
}

class NotificationsMap extends React.Component<NotificationsMapProps, NotificationsMapStates> {
  constructor(props: NotificationsMapProps) {
    super(props)

    this.state = {
      currentLocation: DEFAULT_NOTIFICATIONS_MAP_LOCATION,
      filter: {
        fromDate: moment().startOf('day'),
        toDate: moment(),
        userIds: [],
        types: Object.keys(notificationTypeTexts) as NotificationType[],
        limit: 300,
        locationName: "東京都千代田区丸の内１丁目",
      },
      selectedNotificationId: null,
      isUserFilterInitialized: false,
      isSaveFilter: true,
      map: {
        zoom: 15
      }
    }

    props.fetchObservations()
  }

  componentDidMount() {
    const { notifications } = this.props
    const cookieData = cookie.getItem()

    notifications.addressData = {
      level: cookieData.addressData.level,
      location: cookieData.currentLocation,
    }

    this.setState({
      currentLocation: cookieData.currentLocation,
      filter: {
        fromDate: moment().startOf('day'),
        toDate: moment(),
        userIds: cookieData.filter.userIds,
        types: cookieData.filter.types,
        limit: cookieData.filter.limit,
        locationName: cookieData.filter.locationName,
      },
      map: cookieData.map
    })
  }

  componentDidUpdate() {
    const { isUserFilterInitialized, filter } = this.state
    const { observations, fetchNotifications } = this.props
    const { items } = observations.observations
    const approvedItems = items.filter((item) => item.status === ObservationStatus.Approved)

    if (!isUserFilterInitialized && approvedItems.length !== 0) {
      const userIds = filter.userIds.length !== 0 ? filter.userIds : items.map((item) => item.id)

      setTimeout(() => {
        this.setState({
          isUserFilterInitialized: true,
          filter: { ...filter, userIds },
        })
        fetchNotifications(filter)
      }, 500)
    }
  }

  fetchNotifications() {
    const { filter, isSaveFilter } = this.state

    this.props.fetchNotifications({
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      userIds: filter.userIds,
      types: filter.types,
      limit: filter.limit,
    })
    
    if(isSaveFilter)
      this.saveFilter()
  }

  fetchNotificationsMapLocation(locationName: string) {
    this.props.fetchNotificationsMapLocation({
      locationName: locationName
    })
  }

  saveFilter() {
    const { filter, currentLocation, map } = this.state
    const { notifications } = this.props

    const saveData = {
      currentLocation: currentLocation,
      filter: {
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        userIds: filter.userIds,
        types: filter.types,
        limit: filter.limit,
        locationName: filter.locationName  
      },
      addressData: {
        level: notifications.addressData.level
      },
      map: map
    }
    cookie.setItem(saveData)
  }

  render() {
    const { currentLocation, selectedNotificationId, filter, isSaveFilter, isUserFilterInitialized, map } = this.state
    const { classes, notifications, observations } = this.props
    const { items, fetching } = notifications.map
    const { addressData } = notifications

    const userOptions = observations.observations.items.reduce((options, item) => {
      const isApproved = item.status === ObservationStatus.Approved
      if (isApproved && !options.find((option) => option.value === `${item.id}`))
        options.push({
          label: item.name,
          value: `${item.id}`,
        })
      return options
    }, [] as { label: string; value: string }[])

    const typeOptions = (Object.keys(notificationTypeTexts) as NotificationType[]).map((type) => ({
      value: type,
      label: notificationTypeTexts[type as keyof typeof notificationTypeTexts],
      checked: filter.types.includes(type),
    }))

    const limitOptions = [300, 500, 1000, 1500, 2000].map((limit) => ({
      label: `${limit}`,
      value: limit,
    }))

    let location = addressData.location
    if(location.lat === 0 && location.lng === 0) {
      location = currentLocation
    }

    const changeLocation = (value: ChangeEventValue) => {
      if(!isUserFilterInitialized) return

      addressData.location = value.center
      this.setState({
        currentLocation: value.center,
        map: {
          zoom: value.zoom
        }
      })
    };

    const errorMsg = NOTIFICATIONS_MAP_ERROR_MSG[addressData.level]

    return (
      <MainContainer
        headerItem={
          <Button variant="contained" onClick={() => this.fetchNotifications()}>
            {fetching ? '更新中' : '更新する'}
          </Button>
        }>

        <Grid>
          <SearchForm
            value={filter.locationName}
            label="計測場所"
            onChange={(value) => this.setState({ filter: { ...filter, locationName: value} })}
            onClickIcon={(value) => this.fetchNotificationsMapLocation(value)}
          />
          
          <Grid className={classes.searchAddress_des} >
            <ErrorTypography
              text={errorMsg}
            />
          </Grid>

          <Grid className={classes.searchAddress_des}>
            <NormalTypography
              text={`※こちらに住所を入力し検索してください。MAPをスクロールし、計測場所を調整することができます。\nなお、調整頂いた内容は「検索条件を保存」にチェックを入れて、「絞り込む」ボタンを押下すると保存できます。`}
            />
          </Grid>
        </Grid>

        <SectionHeader>MAP（表示数: {items.length}）</SectionHeader>

        <Grid className={classes.mapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            center={location}
            defaultZoom={map.zoom}
            onChange={changeLocation}
            onChildClick={(key: string) =>
              this.setState({ selectedNotificationId: parseInt(key) })
            }>
            {items.map((item) => {
              const { id, location, pinColor } = item
              if (!location) return <></>
              return (
                <Pin lat={location.latitude} lng={location.longitude} key={id}>
                  <Grid>
                    <Room style={pinStyle(pinColor)} fontSize="large" />
                    {selectedNotificationId === item.id && (
                      <Paper className={classes.infoWindowContainer}>
                        <IconButton
                          className={classes.infoWindowCloseButton}
                          onClick={() => this.setState({ selectedNotificationId: null })}>
                          <Close />
                        </IconButton>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="caption">{item.notifiedDate}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">{item.user.name}</Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="h5">{item.message}</Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    )}
                  </Grid>
                </Pin>
              )
            })}
          </GoogleMapReact>
        </Grid>
        
        <FormContainer>
          <PeriodForm
            withTime
            from={filter.fromDate}
            to={filter.toDate}
            label="対象期間"
            onChange={(from, to) =>
              this.setState({
                filter: {
                  ...filter,
                  fromDate: from || moment().startOf('day'),
                  toDate: to || moment(),
                },
              })
            }
          />
          <MultipleSelectForm
            values={filter.userIds.map((id) => `${id}`)}
            label="対象ユーザー"
            options={userOptions}
            onChange={(values) =>
              this.setState({ filter: { ...filter, userIds: values.map((v) => parseInt(v)) } })
            }
          />
          <CheckBoxForm
            label="対象アラート"
            options={typeOptions}
            onChange={(values) =>
              this.setState({ filter: { ...filter, types: values as NotificationType[] } })
            }
          />
          <SelectForm
            value={`${filter.limit}`}
            label="表示件数"
            options={limitOptions}
            onChange={(value) => this.setState({ filter: { ...filter, limit: parseInt(value) } })}
          />
          <Grid container item xs direction="column" alignItems="flex-end" spacing={1}>
            <Grid item>
              <CheckBoxSingleForm
                option={{label: "検索条件を保存", value: "saveFilter", checked: isSaveFilter}}
                onChange={(value) => this.setState({ isSaveFilter: value })}
              />
              <span className={classes.space}></span>
              <GreenButton
                disabled={fetching}
                variant="contained"
                title={fetching ? '更新中' : '絞り込む'}
                onClick={() => this.fetchNotifications()}
              />
            </Grid>
          </Grid>
        </FormContainer>
      </MainContainer>
    )
  }
}

export default withStyles(styles)(NotificationsMap)
