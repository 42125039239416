import React, { PropsWithChildren } from 'react'
import { GreenButton } from 'components'

interface ButtonProps {
  className?: string
  title?: string
  selected?: boolean
  disabled?: boolean
  onClick?: () => void
}

const CalendarButton = (props: PropsWithChildren<ButtonProps>) => {
  return (
    <GreenButton
      className={props.className}
      title={props.title || ''}
      color={props.selected ? 'primary' : 'default'}
      variant={props.selected || props.disabled ? 'contained' : 'outlined'}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.children}
    </GreenButton>
  )
}

export default CalendarButton
