import React from 'react'
import clsx from 'clsx'
import { Typography, withStyles, WithStyles, Theme, Grid } from '@material-ui/core'
import { createStyles } from '@material-ui/core'
import colors from 'utils/Color'
import { minutesToUnitTimes } from 'utils/time'

const styles = (theme: Theme) =>
  createStyles({
    container: {},
    conditionLabel: {
      fontSize: 11,
    },
    contentContainer: {
      padding: theme.spacing(0.5),
      borderRadius: 5,
      backgroundColor: colors.Background,
    },
    label: {
      fontSize: 10,
    },
    numberLabel: {
      margin: theme.spacing(0.5),
      fontSize: 15,
      fontWeight: 'bold',
    },
    percentageLabel: {
      marginLeft: theme.spacing(3),
      fontSize: 15,
    },
  })

type Props = WithStyles<typeof styles> & {
  condition: 0 | 1 | 2
  minutes: number
  percentage: number
  className?: string
}

const PercentageLabel = (props: Props) => {
  const { classes, className, condition, minutes, percentage } = props
  const labelClass = classes.label
  const numberLabelClass = classes.numberLabel
  const unitTimes = minutesToUnitTimes(minutes)

  let conditionLabel = ''
  let conditionStyle = {}
  switch (condition) {
    case 0:
      conditionLabel = '正常'
      conditionStyle = { color: colors.ConditionGreen }
      break
    case 1:
      conditionLabel = '注意'
      conditionStyle = { color: colors.ConditionYellow }
      break
    case 2:
      conditionLabel = '警告'
      conditionStyle = { color: colors.ConditionRed }
      break
  }

  const timeLabel = (value: number, label: string) => {
    return (
      value > 0 && (
        <>
          <Typography component="span" className={numberLabelClass}>
            {value}
          </Typography>
          <Typography component="span" className={labelClass}>
            {label}
          </Typography>
        </>
      )
    )
  }

  return (
    <Grid container direction="column" spacing={1} className={clsx(classes.container, className)}>
      <Grid item>
        <Typography className={classes.conditionLabel}>
          <Typography component="span" style={conditionStyle}>
            ●{' '}
          </Typography>
          {conditionLabel}
        </Typography>
      </Grid>
      <Grid item container direction="row" alignItems="center" className={classes.contentContainer}>
        <Grid item>
          <Typography>
            {timeLabel(unitTimes.days, '日')}
            {timeLabel(unitTimes.hours, '時間')}
            {timeLabel(unitTimes.minutes, '分')}
          </Typography>
        </Grid>
        <Grid xs item>
          <Typography className={classes.percentageLabel} align="right">
            {(percentage * 100).toFixed(1)}%
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(PercentageLabel)
