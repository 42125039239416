import { channel } from 'redux-saga'
import { put, takeEvery, take, select, SelectEffect } from 'redux-saga/effects'

import apiClient, { UserStatusData, ObservationData } from 'utils/apiClient'
import * as Observations from 'redux/modules/observations'
import UserStatus from 'redux/models/userStatus'
import Observation, { ObservationStatus } from 'redux/models/observation'
import { AppState } from 'redux/store'

type RequestAction = ReturnType<typeof Observations.actions.requestObservation>
type AnswerAction = ReturnType<typeof Observations.actions.sendObservationRequestAnswer>
type ResetAction = ReturnType<typeof Observations.actions.resetObservations>

const redirectChannel = channel()

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

const fetchObservations = function* () {
  try {
    const data: ObservationData[] = yield apiClient.getObservations()
    const items = Observation.loadAll(data)
    yield put(Observations.actions.completedToFetchObservations(items))
    yield put(Observations.actions.fetchStatusList())
  } catch (err) {
    console.error(err)
    yield put(Observations.actions.completedToFetchObservations(null))
  }
}

const fetchObservationRequests = function* () {
  try {
    const data: ObservationData[] = yield apiClient.getObservationRequests()
    const items = Observation.loadAll(data)
    yield put(Observations.actions.completedToFetchObservationRequests(items))
  } catch (err) {
    console.error(err)
    yield put(Observations.actions.completedToFetchObservationRequests(null))
  }
}

const fetchStatuses = function* () {
  try {
    const observations: Observations.ObservationsState = yield selectState((s) => s.observations)
    const observees = observations.observations.items.filter(
      (item) => item.status === ObservationStatus.Approved,
    )

    if (observations.statuses.items.length !== observees.length) {
      // 初期化
      yield put(
        Observations.actions.completedToFetchStatusList(
          observees.map((observee) => new UserStatus(observee.id, observee.name)),
        ),
      )
    }

    const limit = 5
    const count = Math.ceil(observees.length / limit)
    const data: UserStatusData[][] = yield Promise.all(
      Array(count)
        .fill(0)
        .map(async (_, index) => {
          const ids = observees.slice(limit * index, limit * (index + 1)).map((o) => o.id)
          return await apiClient.getUserStatuses(ids)
        }),
    )

    const items = UserStatus.loadAll(data.flatMap((d) => d))
    yield put(Observations.actions.completedToFetchStatusList(items))
  } catch (err) {
    console.error(err)
    yield put(Observations.actions.completedToFetchStatusList(null))
  }
}

const requestObservations = function* (action: RequestAction) {
  try {
    yield apiClient.requestObservation(action.payload.id)
    yield put(Observations.actions.completedToRequestObservation(null))
  } catch (err) {
    console.error(err)
    yield put(Observations.actions.completedToRequestObservation('閲覧申請できませんでした'))
  }
}

const sendAnswer = function* (action: AnswerAction) {
  try {
    const { observation, isApproved } = action.payload
    yield apiClient.sendObservationRequestAnswer(observation.id, isApproved)
    yield put(Observations.actions.completedToSendObservationRequestAnswer(null))
  } catch (err) {
    console.error(err)
    yield put(Observations.actions.completedToSendObservationRequestAnswer('送信できませんでした'))
  }
}

const resetObservations = function* (action: ResetAction) {
  try {
    const user = action.payload
    yield apiClient.resetObservations(user.id)
    yield put(Observations.actions.completedToResetObservations(null))
  } catch (err) {
    console.error(err)
    yield put(
      Observations.actions.completedToResetObservations('閲覧情報をリセットできませんでした'),
    )
  }
}

export default function* dataSaga() {
  yield takeEvery(Observations.FETCH_OBSERVATIONS, fetchObservations)
  yield takeEvery(Observations.FETCH_OBSERVATION_REQUESTS, fetchObservationRequests)
  yield takeEvery(Observations.FETCH_STATUS_LIST, fetchStatuses)
  yield takeEvery(Observations.REQUEST_OBSERVATION, requestObservations)
  yield takeEvery(Observations.SEND_OBSERVATION_REQUEST_ANSWER, sendAnswer)
  yield takeEvery(Observations.RESET_OBSERVATIONS, resetObservations)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
