import React from 'react'
import clsx from 'clsx'
import {
  createStyles,
  Theme,
  TableCellProps,
  TableContainer,
  Table as MTable,
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  WithStyles,
  Button,
  Grid,
  Typography,
  TablePagination,
} from '@material-ui/core'
import colors from 'utils/Color'
import { Edit, Delete } from '@material-ui/icons'

const styles = (theme: Theme) =>
  createStyles({
    headCell: {
      color: colors.LightGray,
      fontSize: 15,
      fontWeight: 'bold',
      borderBottom: 'none',
    },
    cell: {
      minHeight: 50,
      backgroundColor: theme.palette.common.white,
      borderBottom: `${theme.spacing(1)}px solid ${colors.Background}`,
      borderRight: `${theme.spacing(1)}px solid ${colors.Background}`,
    },
    accessoryCell: {
      width: 80,
      height: 80,
      padding: 0,
      borderBottom: `${theme.spacing(1)}px solid ${colors.Background}`,
      borderRight: `${theme.spacing(1)}px solid ${colors.Background}`,
    },
    editCell: {
      backgroundColor: colors.Green,
    },
    deleteCell: {
      backgroundColor: colors.Red,
    },
    cellButton: {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      color: theme.palette.common.white,
    },
    cellButtonText: {
      color: theme.palette.common.white,
    },
  })

export type TableColumnType = string | React.ReactElement
export type AccessoryCell = React.ReactElement<TableCellProps> | 'edit' | 'delete'

type TableProps = WithStyles<typeof styles> & {
  fetched: boolean
  headers: string[]
  rows: TableColumnType[][]
  accessories?: AccessoryCell[][]
  noContentItem?: React.ReactElement

  pagination?: {
    count: number
    rowsPerPage: number
    page: number
  }
  onChangePage?: (page: number) => void

  onEdit?: (index: number) => void
  onDelete?: (index: number) => void
}

class Table extends React.Component<TableProps> {
  renderEditCell(index: number) {
    const { classes, onEdit } = this.props

    return (
      <TableCell key={`edit-${index}`} className={clsx(classes.accessoryCell, classes.editCell)}>
        <Button className={classes.cellButton} onClick={() => onEdit && onEdit(index)}>
          <Grid container direction="column">
            <Grid>
              <Edit fontSize="large" />
            </Grid>
            <Grid>
              <Typography className={classes.cellButtonText}>編集</Typography>
            </Grid>
          </Grid>
        </Button>
      </TableCell>
    )
  }

  renderDeleteCell(index: number) {
    const { classes, onDelete } = this.props

    return (
      <TableCell
        key={`delete-${index}`}
        className={clsx(classes.accessoryCell, classes.deleteCell)}>
        <Button className={classes.cellButton} onClick={() => onDelete && onDelete(index)}>
          <Grid container direction="column">
            <Grid>
              <Delete fontSize="large" />
            </Grid>
            <Grid>
              <Typography className={classes.cellButtonText}>削除</Typography>
            </Grid>
          </Grid>
        </Button>
      </TableCell>
    )
  }

  render() {
    const {
      classes,
      fetched,
      headers,
      rows,
      noContentItem,
      accessories,
      pagination,
      onChangePage,
    } = this.props

    const accessoryCells =
      accessories?.map((acc, index) =>
        acc.map((item) => {
          if (item === 'edit') {
            return this.renderEditCell(index)
          } else if (item === 'delete') {
            return this.renderDeleteCell(index)
          } else {
            return item
          }
        }),
      ) || []

    return (
      <TableContainer>
        {fetched && rows.length === 0 && noContentItem}
        {rows.length > 0 && (
          <MTable>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell className={classes.headCell} key={index}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, ri) => (
                <TableRow key={ri}>
                  {row.map((col, ci) => (
                    <TableCell key={ci} component="th" scope="row" className={classes.cell}>
                      {col}
                    </TableCell>
                  ))}
                  {accessoryCells[ri]}
                </TableRow>
              ))}
            </TableBody>
            {pagination && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={pagination.count}
                    rowsPerPage={pagination.rowsPerPage}
                    page={pagination.page - 1}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={(_, page) => onChangePage && onChangePage(page + 1)}
                  />
                </TableRow>
              </TableFooter>
            )}
          </MTable>
        )}
      </TableContainer>
    )
  }
}

export default withStyles(styles)(Table)
