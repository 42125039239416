import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as observationsActions, ObservationsState } from 'redux/modules/observations'
import { actions as alertsActions, AlertsState, SendPayload } from 'redux/modules/alerts'
import Screen from './Dashboard'

export interface DashboardActions {
  fetchObservations: () => Action<void>
  fetchStatuses: () => Action<void>
  sendAlertAction: (payload: SendPayload) => Action<SendPayload>
}

export interface DashboardStoreStates {
  alerts: AlertsState
  observations: ObservationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): DashboardActions {
  return {
    fetchObservations: () => dispatch(observationsActions.fetchObservations()),
    fetchStatuses: () => dispatch(observationsActions.fetchStatusList()),
    sendAlertAction: (payload) => dispatch(alertsActions.sendAlertAction(payload)),
  }
}

function mapStateToProps(appState: AppState): DashboardStoreStates {
  return { alerts: appState.alerts, observations: appState.observations }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
